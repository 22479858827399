<template>
    <div class="container">

        <div class="back-link mt-3">
            <button @click="$router.push('/')" class="btn btn-secondary">Back</button>
        </div>

        <div class="user-avatar px-5 mb-4">
            <img :src="avatarUrl" class="user-avatar-image img-fluid">
        </div>

        <!-- Button to allow notifications, hidden if notifications are already enabled -->
        <div class="mb-3 d-grid" v-if="showNotificationButton">
            <button class="btn btn-primary" @click="requestNotificationPermission">Allow notifications</button>
        </div>

        <h2>Your xName</h2>

        <div class="form-floating mb-3">
            <input type="text" id="xname" name="xname" class="form-control" :class="{ 'is-invalid' : this.fieldErrors.xname }" v-model="user.xname" @change="checkXname">
            <label for="xname">Your unique identifier for crossworx</label>
            <span class="text-danger" v-if="this.fieldErrors.xname">{{ this.fieldErrors.xname }}</span>
        </div>

        <h2>Privacy</h2>

        <p><small>Choose whether you want to be displayed only in firstlines or further down in the hierarchy as well.</small></p>

        <div class="mb-3 d-flex align-items-center">
            <input type="checkbox" id="privacy_mode" name="privacy_mode" class="switch-checkbox" v-model="user.privacy_mode">
            <label for="privacy_mode" class="switch"></label>
            <label for="privacy_mode" style="font-size: 1rem;" class="ms-3">Only show me in firstlines</label>
        </div>

        <h2>General data</h2>

        <div class="form-floating mb-3">
            <select id="user_title_id" name="user_title_id" class="form-control" v-model="user.title">
                <option value="0">Select</option>
                <option value="1">Mr</option>
                <option value="2">Ms/Mrs</option>
            </select>
            <label for="user_title_id">Salutation</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="user_first_name" name="user_first_name" class="form-control" v-model="user.first_name">
            <label for="user_first_name">First name</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" nid="user_second_name" ame="user_second_name" class="form-control" v-model="user.second_name">
            <label for="user_second_name">Last name</label>
        </div>

        <div class="form-floating mb-3">
            <input type="date" id="user_birth_date" name="user_birth_date" class="form-control" v-model="user.birth_date">
            <label for="user_birth_date">Birthday</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="user_phone" name="user_phone" class="form-control" v-model="user.phone">
            <label for="user_phone">Phone number</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="user_email" name="user_email" class="form-control" :value="user.email" disabled>
            <label for="user_email">Email</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="user_username" name="user_username" class="form-control" :value="user.username" disabled>
            <label for="user_email">Username</label>
        </div>

        <h2>Company data</h2>

        <div class="form-floating mb-3">
            <input type="text" id="company_name" name="company_name" class="form-control" v-model="user.company">
            <label for="user_phone">Company name</label>
        </div>

        <div class="form-floating mb-3">
            <select id="company_legal_form" name="company_legal_form" class="form-control" v-model="user.legal_form">
                <option value="0">Please select</option>
                <option value="1">Sole Proprietorship</option>
                <option value="2">Partnership (GbR)</option>
                <option value="3">Registered Merchant (Einzelkaufleute)</option>
                <option value="4">General Partnership (OHG)</option>
                <option value="5">Limited Partnership (KG)</option>
                <option value="6">Limited Liability Company (GmbH)</option>
                <option value="7">Entrepreneurial Company (UG)</option>
                <option value="8">Public Limited Company (AG)</option>
            </select>
            <label for="user_phone">Legal form</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_branch" name="company_branch" class="form-control" v-model="user.branch">
            <label for="company_branch">Sector</label>
        </div>

        <div class="form-floating mb-3">
            <select id="company_court_id" name="company_court_id" class="form-control" v-model="user.court_id">
                <option value="0">Please select</option>
                <option value="1">Amtsgericht Aachen</option>
                <option value="2">Amtsgericht Aalen</option>
                <option value="3">Amtsgericht Achern</option>
                <option value="4">Amtsgericht Achim</option>
                <option value="5">Amtsgericht Adelsheim</option>
                <option value="6">Amtsgericht Ahaus</option>
                <option value="7">Amtsgericht Ahlen</option>
                <option value="8">Amtsgericht Ahrensburg</option>
                <option value="9">Amtsgericht Aichach</option>
                <option value="10">Amtsgericht Albstadt</option>
                <option value="11">Amtsgericht Alfeld (Leine)</option>
                <option value="12">Amtsgericht Alsfeld</option>
                <option value="13">Amtsgericht Altena</option>
                <option value="14">Amtsgericht Altenburg</option>
                <option value="15">Amtsgericht Altenkirchen (Westerwald)</option>
                <option value="16">Amtsgericht Altötting</option>
                <option value="17">Amtsgericht Alzey</option>
                <option value="18">Amtsgericht Amberg</option>
                <option value="19">Amtsgericht Amstadt</option>
                <option value="20">Amtsgericht Andernach</option>
                <option value="21">Amtsgericht Ansbach</option>
                <option value="22">Amtsgericht Apolda</option>
                <option value="23">Amtsgericht Arnsberg</option>
                <option value="24">Amtsgericht Aschaffenburg</option>
                <option value="25">Amtsgericht Aschersieben</option>
                <option value="26">Amtsgericht Aue</option>
                <option value="27">Amtsgericht Auerbach</option>
                <option value="28">Amtsgericht Augsburg</option>
                <option value="29">Amtsgericht Aurich</option>
                <option value="30">Amtsgericht Backnang</option>
                <option value="31">Amtsgericht Bad Berleburg</option>
                <option value="32">Amtsgericht Bad Dürkheim</option>
                <option value="33">Amtsgericht Bad Freienwalde (Oder)</option>
                <option value="34">Amtsgericht Bad Gandersheim</option>
                <option value="35">Amtsgericht Bad Hersfeld</option>
                <option value="36">Amtsgericht Bad Homburg</option>
                <option value="37">Amtsgericht Bad Iburg</option>
                <option value="38">Amtsgericht Bad Kissingen</option>
                <option value="39">Amtsgericht Bad Kreuznach</option>
                <option value="40">Amtsgericht Bad Liebenwerda</option>
                <option value="41">Amtsgericht Bad Mergentheim</option>
                <option value="42">Amtsgericht Bad Neuenahr-Ahrweiler</option>
                <option value="43">Amtsgericht Bad Neustadt a.d. Saale</option>
                <option value="44">Amtsgericht Bad Oeynhausen</option>
                <option value="45">Amtsgericht Bad Salzungen</option>
                <option value="46">Amtsgericht Bad Saulgau</option>
                <option value="47">Amtsgericht Bad Schwalbach</option>
                <option value="48">Amtsgericht Bad Segeberg</option>
                <option value="49">Amtsgericht Bad Sobemheim</option>
                <option value="50">Amtsgericht Bad Säckingen</option>
                <option value="51">Amtsgericht Bad Urach</option>
                <option value="52">Amtsgericht Bad Waldsee</option>
                <option value="53">Amtsgericht Baden-Baden</option>
                <option value="54">Amtsgericht Balingen</option>
                <option value="55">Amtsgericht Bamberg</option>
                <option value="56">Amtsgericht Bautzen</option>
                <option value="57">Amtsgericht Bayreuth</option>
                <option value="58">Amtsgericht Beckum</option>
                <option value="59">Amtsgericht Bensheim</option>
                <option value="60">Amtsgericht Bergheim</option>
                <option value="61">Amtsgericht Bergisch Gladbach</option>
                <option value="62">Amtsgericht Bernau bei Berlin</option>
                <option value="63">Amtsgericht Bernburg</option>
                <option value="64">Amtsgericht Bernkastel-Kues</option>
                <option value="65">Amtsgericht Bersenbriick</option>
                <option value="66">Amtsgericht Besigheim</option>
                <option value="67">Amtsgericht Betzdorf</option>
                <option value="68">Amtsgericht Biberach/Riss</option>
                <option value="69">Amtsgericht Biedenkopf</option>
                <option value="70">Amtsgericht Bielefeld</option>
                <option value="71">Amtsgericht Biidingen</option>
                <option value="72">Amtsgericht Bingen am Rhein</option>
                <option value="73">Amtsgericht Bitburg</option>
                <option value="74">Amtsgericht Bitterfeld-Wolfen</option>
                <option value="75">Amtsgericht Blomberg</option>
                <option value="76">Amtsgericht Bocholt</option>
                <option value="77">Amtsgericht Bochum</option>
                <option value="78">Amtsgericht Bonn</option>
                <option value="79">Amtsgericht Borken</option>
                <option value="80">Amtsgericht Borna</option>
                <option value="81">Amtsgericht Bottrop</option>
                <option value="82">Amtsgericht BQckeburg</option>
                <option value="83">Amtsgericht Brackenheim</option>
                <option value="84">Amtsgericht Brake (Unterweser)</option>
                <option value="85">Amtsgericht Brakei</option>
                <option value="86">Amtsgericht Brandenburg an der Havel</option>
                <option value="87">Amtsgericht Braunschweig</option>
                <option value="88">Amtsgericht Breisach am Rhein</option>
                <option value="89">Amtsgericht Bremen</option>
                <option value="90">Amtsgericht Bremen-Blumenthal</option>
                <option value="91">Amtsgericht Bremerhaven</option>
                <option value="92">Amtsgericht Bremervorde</option>
                <option value="93">Amtsgericht Bretten</option>
                <option value="94">Amtsgericht Brilon</option>
                <option value="95">Amtsgericht Bruchsal</option>
                <option value="96">Amtsgericht Brühl</option>
                <option value="97">Amtsgericht Buchen</option>
                <option value="98">Amtsgericht Burg</option>
                <option value="99">Amtsgericht Burgdorf</option>
                <option value="100">Amtsgericht Burgwedel</option>
                <option value="101">Amtsgericht Buxtehude</option>
                <option value="102">Amtsgericht Bühl</option>
                <option value="103">Amtsgericht Bünde</option>
                <option value="104">Amtsgericht Böblingen</option>
                <option value="105">Amtsgericht Calw</option>
                <option value="106">Amtsgericht Castrop-Rauxel</option>
                <option value="107">Amtsgericht Celle</option>
                <option value="108">Amtsgericht Cham</option>
                <option value="109">Amtsgericht Charlottenburg</option>
                <option value="110">Amtsgericht Chemnitz</option>
                <option value="111">Amtsgericht Clausthal-Zellerfeld</option>
                <option value="112">Amtsgericht Cloppenburg</option>
                <option value="113">Amtsgericht Coburg</option>
                <option value="114">Amtsgericht Cochem</option>
                <option value="115">Amtsgericht Coesfeld</option>
                <option value="116">Amtsgericht Cottbus</option>
                <option value="117">Amtsgericht Crailsheim</option>
                <option value="118">Amtsgericht Cuxhaven</option>
                <option value="119">Amtsgericht Dachau</option>
                <option value="120">Amtsgericht Dannenberg (Elbe)</option>
                <option value="121">Amtsgericht Darmstadt</option>
                <option value="122">Amtsgericht Daun</option>
                <option value="123">Amtsgericht Deggendorf</option>
                <option value="124">Amtsgericht Delbrück</option>
                <option value="125">Amtsgericht Delmenhorst</option>
                <option value="126">Amtsgericht Dessau-Roßlau</option>
                <option value="127">Amtsgericht Detmold</option>
                <option value="128">Amtsgericht Dieburg</option>
                <option value="129">Amtsgericht Diephoiz</option>
                <option value="130">Amtsgericht Diez</option>
                <option value="131">Amtsgericht Dillenburg</option>
                <option value="132">Amtsgericht Dillingen an der Donau</option>
                <option value="133">Amtsgericht Dinslaken</option>
                <option value="134">Amtsgericht Dippoldiswalde</option>
                <option value="135">Amtsgericht Donaueschingen</option>
                <option value="136">Amtsgericht Dorsten</option>
                <option value="137">Amtsgericht Dortmund</option>
                <option value="138">Amtsgericht Dresden</option>
                <option value="139">Amtsgericht Duderstadt</option>
                <option value="140">Amtsgericht Duisburg</option>
                <option value="141">Amtsgericht Duisburg-Hamborn</option>
                <option value="142">Amtsgericht Duisburg-Ruhrort</option>
                <option value="143">Amtsgericht Dülmen</option>
                <option value="144">Amtsgericht Düren</option>
                <option value="145">Amtsgericht Düsseldorf</option>
                <option value="146">Amtsgericht Döbeln</option>
                <option value="147">Amtsgericht Ebersberg</option>
                <option value="148">Amtsgericht Eberswalde</option>
                <option value="149">Amtsgericht Eckernförde</option>
                <option value="150">Amtsgericht Eggenfelden</option>
                <option value="151">Amtsgericht Ehingen/Donau</option>
                <option value="152">Amtsgericht Eilenburg</option>
                <option value="153">Amtsgericht Einbeck</option>
                <option value="154">Amtsgericht Eisenach</option>
                <option value="155">Amtsgericht Eisenhüttenstadt</option>
                <option value="156">Amtsgericht Eisleben</option>
                <option value="157">Amtsgericht Ellwangen (Jagst)</option>
                <option value="158">Amtsgericht Elmshorn</option>
                <option value="159">Amtsgericht Elze</option>
                <option value="160">Amtsgericht Emden</option>
                <option value="161">Amtsgericht Emmendingen</option>
                <option value="162">Amtsgericht Emmerich am Rhein</option>
                <option value="163">Amtsgericht Erding</option>
                <option value="164">Amtsgericht Erfurt</option>
                <option value="165">Amtsgericht Erkelenz</option>
                <option value="166">Amtsgericht Erlangen</option>
                <option value="167">Amtsgericht Eschwege</option>
                <option value="168">Amtsgericht Eschweiler</option>
                <option value="169">Amtsgericht Essen</option>
                <option value="170">Amtsgericht Essen-Borbeck</option>
                <option value="171">Amtsgericht Essen-Steele</option>
                <option value="172">Amtsgericht Esslingen am Neckar</option>
                <option value="173">Amtsgericht Ettenheim</option>
                <option value="174">Amtsgericht Ettlingen</option>
                <option value="175">Amtsgericht Euskirchen</option>
                <option value="176">Amtsgericht Eutin</option>
                <option value="177">Amtsgericht Flensburg</option>
                <option value="178">Amtsgericht Forchheim</option>
                <option value="179">Amtsgericht Frankenberg (Eder)</option>
                <option value="180">Amtsgericht Frankenthal (Pfalz)</option>
                <option value="181">Amtsgericht Frankfurt (Oder)</option>
                <option value="182">Amtsgericht Frankfurt am Main</option>
                <option value="183">Amtsgericht Freiberg</option>
                <option value="184">Amtsgericht Freiburg im Breisgau</option>
                <option value="185">Amtsgericht Freising</option>
                <option value="186">Amtsgericht Freudenstadt</option>
                <option value="187">Amtsgericht Freyung</option>
                <option value="188">Amtsgericht Friedberg (Hessen)</option>
                <option value="189">Amtsgericht Fritzlar</option>
                <option value="190">Amtsgericht Fulda</option>
                <option value="191">Amtsgericht Fürstenfeldbruck</option>
                <option value="192">Amtsgericht Fürstenwalde/Spree</option>
                <option value="193">Amtsgericht Fürth</option>
                <option value="194">Amtsgericht Gardelegen</option>
                <option value="195">Amtsgericht Garmisch-Partenkirchen</option>
                <option value="196">Amtsgericht Geestland</option>
                <option value="197">Amtsgericht Geilenkirchen</option>
                <option value="198">Amtsgericht Geislingen</option>
                <option value="199">Amtsgericht Geldern</option>
                <option value="200">Amtsgericht Gelnhausen</option>
                <option value="201">Amtsgericht Gelsenkirchen</option>
                <option value="202">Amtsgericht Gelsenkirchen-Buer</option>
                <option value="203">Amtsgericht Gemünden am Main</option>
                <option value="204">Amtsgericht Gengenbach</option>
                <option value="205">Amtsgericht Gera</option>
                <option value="206">Amtsgericht Germersheim</option>
                <option value="207">Amtsgericht Gernsbach</option>
                <option value="208">Amtsgericht GieBen</option>
                <option value="209">Amtsgericht Gifhom</option>
                <option value="210">Amtsgericht Gladbeck</option>
                <option value="211">Amtsgericht Goslar</option>
                <option value="212">Amtsgericht Gotha</option>
                <option value="213">Amtsgericht Greifswald</option>
                <option value="214">Amtsgericht Greiz</option>
                <option value="215">Amtsgericht Grevenbroich</option>
                <option value="216">Amtsgericht Grimma</option>
                <option value="217">Amtsgericht GroB-Gerau</option>
                <option value="218">Amtsgericht Gronau</option>
                <option value="219">Amtsgericht Grünstadt</option>
                <option value="220">Amtsgericht Gummersbach</option>
                <option value="221">Amtsgericht Gustrow</option>
                <option value="222">Amtsgericht Günzburg</option>
                <option value="223">Amtsgericht Gütersloh</option>
                <option value="224">Amtsgericht Göppingen</option>
                <option value="225">Amtsgericht Görlitz</option>
                <option value="226">Amtsgericht Göttingen</option>
                <option value="227">Amtsgericht Hagen</option>
                <option value="228">Amtsgericht Halberstadt</option>
                <option value="229">Amtsgericht Haldensleben</option>
                <option value="230">Amtsgericht Halle (Saale)</option>
                <option value="231">Amtsgericht Halle / Westfalen</option>
                <option value="232">Amtsgericht Hamburg</option>
                <option value="233">Amtsgericht Hamburg-Altona</option>
                <option value="234">Amtsgericht Hamburg-Barmbek</option>
                <option value="235">Amtsgericht Hamburg-Bergedorf</option>
                <option value="236">Amtsgericht Hamburg-Blankenese</option>
                <option value="237">Amtsgericht Hamburg-Harburg</option>
                <option value="238">Amtsgericht Hamburg-St.Georg</option>
                <option value="239">Amtsgericht Hamburg-Wandsbek</option>
                <option value="240">Amtsgericht Hameln</option>
                <option value="241">Amtsgericht Hamm</option>
                <option value="242">Amtsgericht Hanau</option>
                <option value="243">Amtsgericht Hann.MQnden</option>
                <option value="244">Amtsgericht Hannover</option>
                <option value="245">Amtsgericht Hattingen</option>
                <option value="246">Amtsgericht Haßfurt</option>
                <option value="247">Amtsgericht Hechingen</option>
                <option value="248">Amtsgericht Heidelberg</option>
                <option value="249">Amtsgericht Heidenheim a. d. Brenz</option>
                <option value="250">Amtsgericht Heilbad Heiligenstadt</option>
                <option value="251">Amtsgericht Heilbronn</option>
                <option value="252">Amtsgericht Heinsberg</option>
                <option value="253">Amtsgericht Helmstedt</option>
                <option value="254">Amtsgericht Herford</option>
                <option value="255">Amtsgericht Hermeskeil</option>
                <option value="256">Amtsgericht Herne</option>
                <option value="257">Amtsgericht Herne-Wanne</option>
                <option value="258">Amtsgericht Hersbruck</option>
                <option value="259">Amtsgericht Herzberg am Harz</option>
                <option value="260">Amtsgericht Hiidburghausert</option>
                <option value="261">Amtsgericht Hildesheim</option>
                <option value="262">Amtsgericht Hof</option>
                <option value="263">Amtsgericht Hohenstein-Ernstthal</option>
                <option value="264">Amtsgericht Holzminden</option>
                <option value="265">Amtsgericht Homburg</option>
                <option value="266">Amtsgericht Horb am Neckar</option>
                <option value="267">Amtsgericht Hoyerswerda</option>
                <option value="268">Amtsgericht Hunfeld</option>
                <option value="269">Amtsgericht Husum</option>
                <option value="270">Amtsgericht Höxter</option>
                <option value="271">Amtsgericht Ibbenbüren</option>
                <option value="272">Amtsgericht Idar-Oberstein</option>
                <option value="273">Amtsgericht Idstein</option>
                <option value="274">Amtsgericht Ingolstadt</option>
                <option value="275">Amtsgericht Iserlohn</option>
                <option value="276">Amtsgericht Itzehoe</option>
                <option value="277">Amtsgericht Jena</option>
                <option value="278">Amtsgericht Jever</option>
                <option value="279">Amtsgericht Jülich</option>
                <option value="280">Amtsgericht Kaiserslautern</option>
                <option value="281">Amtsgericht Kamen</option>
                <option value="282">Amtsgericht Kamenz</option>
                <option value="283">Amtsgericht Kandel</option>
                <option value="284">Amtsgericht Karlsruhe</option>
                <option value="285">Amtsgericht Karlsruhe-Durlach</option>
                <option value="286">Amtsgericht Kassel</option>
                <option value="287">Amtsgericht Kaufbeuren</option>
                <option value="288">Amtsgericht Kehl</option>
                <option value="289">Amtsgericht Kelheim</option>
                <option value="290">Amtsgericht Kempen</option>
                <option value="291">Amtsgericht Kempten (Allgäu)</option>
                <option value="292">Amtsgericht Kenzingen</option>
                <option value="293">Amtsgericht Kerpen</option>
                <option value="294">Amtsgericht Kiel</option>
                <option value="295">Amtsgericht Kirchhain</option>
                <option value="296">Amtsgericht Kirchheim unter Teck</option>
                <option value="297">Amtsgericht Kitzingen</option>
                <option value="298">Amtsgericht Kleve</option>
                <option value="299">Amtsgericht Koblenz</option>
                <option value="300">Amtsgericht Konigstein im Taunus</option>
                <option value="301">Amtsgericht Konstanz</option>
                <option value="302">Amtsgericht Korbach</option>
                <option value="303">Amtsgericht Krefeld</option>
                <option value="304">Amtsgericht Kronach</option>
                <option value="305">Amtsgericht Kulmbach</option>
                <option value="306">Amtsgericht Kusel</option>
                <option value="307">Amtsgericht Künzelsau</option>
                <option value="308">Amtsgericht Köln</option>
                <option value="309">Amtsgericht Königs Wusterhausen</option>
                <option value="310">Amtsgericht Königswinter</option>
                <option value="311">Amtsgericht Köpenick</option>
                <option value="312">Amtsgericht Köthen</option>
                <option value="313">Amtsgericht Lahnstein</option>
                <option value="314">Amtsgericht Lahr/Schwarzwald</option>
                <option value="315">Amtsgericht Lampertheim</option>
                <option value="316">Amtsgericht Landau a.d.lsar</option>
                <option value="317">Amtsgericht Landau in der Pfalz</option>
                <option value="318">Amtsgericht Landsberg am Lech</option>
                <option value="319">Amtsgericht Landshut</option>
                <option value="320">Amtsgericht Landstuhl</option>
                <option value="321">Amtsgericht Langen (Hessen)</option>
                <option value="322">Amtsgericht Langenburg</option>
                <option value="323">Amtsgericht Langenfeld</option>
                <option value="324">Amtsgericht Laufen</option>
                <option value="325">Amtsgericht Lebach</option>
                <option value="326">Amtsgericht Leer (Ostfriesland)</option>
                <option value="327">Amtsgericht Lehrte</option>
                <option value="328">Amtsgericht Leipzig</option>
                <option value="329">Amtsgericht Lemgo</option>
                <option value="330">Amtsgericht Lennestadt</option>
                <option value="331">Amtsgericht Leonberg</option>
                <option value="332">Amtsgericht Leutkirch</option>
                <option value="333">Amtsgericht Leverkusen</option>
                <option value="334">Amtsgericht Lichtenberg</option>
                <option value="335">Amtsgericht Lichtenfels</option>
                <option value="336">Amtsgericht Limburg a.d. Lahn</option>
                <option value="337">Amtsgericht Lindau (Bodensee)</option>
                <option value="338">Amtsgericht Lingen (Ems)</option>
                <option value="339">Amtsgericht Linz am Rhein</option>
                <option value="340">Amtsgericht Lippstadt</option>
                <option value="341">Amtsgericht Luckenwalde</option>
                <option value="342">Amtsgericht Ludwigsburg</option>
                <option value="343">Amtsgericht Ludwigshafen am Rhein</option>
                <option value="344">Amtsgericht Ludwigslust</option>
                <option value="345">Amtsgericht Luneburg</option>
                <option value="346">Amtsgericht Lübbecke</option>
                <option value="347">Amtsgericht Lübben (Spreewald)</option>
                <option value="348">Amtsgericht Lübeck</option>
                <option value="349">Amtsgericht Lüdenscheid</option>
                <option value="350">Amtsgericht Lüdinghausen</option>
                <option value="351">Amtsgericht Lünen</option>
                <option value="352">Amtsgericht Lörrach</option>
                <option value="353">Amtsgericht Magdeburg</option>
                <option value="354">Amtsgericht Mainz</option>
                <option value="355">Amtsgericht Mannheim</option>
                <option value="356">Amtsgericht Marbach</option>
                <option value="357">Amtsgericht Marburg</option>
                <option value="358">Amtsgericht Marienberg</option>
                <option value="359">Amtsgericht Marl</option>
                <option value="360">Amtsgericht Marsberg</option>
                <option value="361">Amtsgericht Maulbronn</option>
                <option value="362">Amtsgericht Mayen</option>
                <option value="363">Amtsgericht Medebach</option>
                <option value="364">Amtsgericht Meinerzhagen</option>
                <option value="365">Amtsgericht Meiningen</option>
                <option value="366">Amtsgericht Meißen</option>
                <option value="367">Amtsgericht Meldorf</option>
                <option value="368">Amtsgericht Melsungen</option>
                <option value="369">Amtsgericht Memmingen</option>
                <option value="370">Amtsgericht Menden</option>
                <option value="371">Amtsgericht Meppen</option>
                <option value="372">Amtsgericht Merseburg</option>
                <option value="373">Amtsgericht Merzig</option>
                <option value="374">Amtsgericht Meschede</option>
                <option value="375">Amtsgericht Mettmann</option>
                <option value="376">Amtsgericht Michelstadt</option>
                <option value="377">Amtsgericht Miesbach</option>
                <option value="378">Amtsgericht Minden</option>
                <option value="379">Amtsgericht Mitte</option>
                <option value="380">Amtsgericht Moers</option>
                <option value="381">Amtsgericht Monschau</option>
                <option value="382">Amtsgericht Montabaur</option>
                <option value="383">Amtsgericht Mosbach</option>
                <option value="384">Amtsgericht Mühldorf am Inn</option>
                <option value="385">Amtsgericht Mühlhausen</option>
                <option value="386">Amtsgericht Mülheim an der Ruhr</option>
                <option value="387">Amtsgericht Müllheim</option>
                <option value="388">Amtsgericht München</option>
                <option value="389">Amtsgericht Münsingen</option>
                <option value="390">Amtsgericht Münster</option>
                <option value="391">Amtsgericht Mönchengladbach</option>
                <option value="392">Amtsgericht Mönchengladbach-Rheydt</option>
                <option value="393">Amtsgericht Nagold</option>
                <option value="394">Amtsgericht Nauen</option>
                <option value="395">Amtsgericht Naumburg</option>
                <option value="396">Amtsgericht Neresheim</option>
                <option value="397">Amtsgericht Nettetal</option>
                <option value="398">Amtsgericht Neu-Ulm</option>
                <option value="399">Amtsgericht Neubrandenburg</option>
                <option value="400">Amtsgericht Neuburg a. d. Donau</option>
                <option value="401">Amtsgericht Neukölln</option>
                <option value="402">Amtsgericht Neumarkt in der Oberpfalz</option>
                <option value="403">Amtsgericht Neumünster</option>
                <option value="404">Amtsgericht Neunkirchen</option>
                <option value="405">Amtsgericht Neuruppin</option>
                <option value="406">Amtsgericht Neuss</option>
                <option value="407">Amtsgericht Neustadt am Rubenberge</option>
                <option value="408">Amtsgericht Neustadt an der Aisch</option>
                <option value="409">Amtsgericht Neustadt an der Weinstraße</option>
                <option value="410">Amtsgericht Neuwied</option>
                <option value="411">Amtsgericht Niebüll</option>
                <option value="412">Amtsgericht Nienburg (Weser)</option>
                <option value="413">Amtsgericht Norden</option>
                <option value="414">Amtsgericht Nordenham</option>
                <option value="415">Amtsgericht Norderstedt</option>
                <option value="416">Amtsgericht Nordhausen</option>
                <option value="417">Amtsgericht Nordhorn</option>
                <option value="418">Amtsgericht Northeim</option>
                <option value="419">Amtsgericht Nürnberg</option>
                <option value="420">Amtsgericht Nürtingen</option>
                <option value="421">Amtsgericht Nördlingen</option>
                <option value="422">Amtsgericht Obemburg am Main</option>
                <option value="423">Amtsgericht Oberhausen</option>
                <option value="424">Amtsgericht Oberkirch</option>
                <option value="425">Amtsgericht Oberndorf am Neckar</option>
                <option value="426">Amtsgericht Offenbach am Main</option>
                <option value="427">Amtsgericht Offenburg</option>
                <option value="428">Amtsgericht Oldenburg (Oldenburg)</option>
                <option value="429">Amtsgericht Oldenburq (Holstein)</option>
                <option value="430">Amtsgericht Olpe</option>
                <option value="431">Amtsgericht Oranienburg</option>
                <option value="432">Amtsgericht Oschersleben</option>
                <option value="433">Amtsgericht Osnabruck</option>
                <option value="434">Amtsgericht Osterholz-Scharmbeck</option>
                <option value="435">Amtsgericht Osterode am Harz</option>
                <option value="436">Amtsgericht Ottemdorf</option>
                <option value="437">Amtsgericht Ottweiler</option>
                <option value="438">Amtsgericht Paderborn</option>
                <option value="439">Amtsgericht Pankow/Weißensee</option>
                <option value="440">Amtsgericht Papenburg</option>
                <option value="441">Amtsgericht Pasewalk</option>
                <option value="442">Amtsgericht Passau</option>
                <option value="443">Amtsgericht Peine</option>
                <option value="444">Amtsgericht Perleberg</option>
                <option value="445">Amtsgericht Pfaffenhofen a.d.llm</option>
                <option value="446">Amtsgericht Pforzheim</option>
                <option value="447">Amtsgericht Philippsburg</option>
                <option value="448">Amtsgericht Pinneberg</option>
                <option value="449">Amtsgericht Pirmasens</option>
                <option value="450">Amtsgericht Pirna</option>
                <option value="451">Amtsgericht Plauen</option>
                <option value="452">Amtsgericht Plettenberg</option>
                <option value="453">Amtsgericht Plön</option>
                <option value="454">Amtsgericht Potsdam</option>
                <option value="455">Amtsgericht Prenzlau</option>
                <option value="456">Amtsgericht Prüm</option>
                <option value="457">Amtsgericht Pößneck</option>
                <option value="458">Amtsgericht Quedlinburg</option>
                <option value="459">Amtsgericht Radolfzell am Bodensee</option>
                <option value="460">Amtsgericht Rahden</option>
                <option value="461">Amtsgericht Rastatt</option>
                <option value="462">Amtsgericht Rathenow</option>
                <option value="463">Amtsgericht Ratingen</option>
                <option value="464">Amtsgericht Ratzeburg</option>
                <option value="465">Amtsgericht Ravensburg</option>
                <option value="466">Amtsgericht Recklinghausen</option>
                <option value="467">Amtsgericht Regensburg</option>
                <option value="468">Amtsgericht Reinbek</option>
                <option value="469">Amtsgericht Remscheid</option>
                <option value="470">Amtsgericht Rendsburg</option>
                <option value="471">Amtsgericht Reutlingen</option>
                <option value="472">Amtsgericht Rheda-Wiedenbrück</option>
                <option value="473">Amtsgericht Rheinbach</option>
                <option value="474">Amtsgericht Rheinberg</option>
                <option value="475">Amtsgericht Rheine</option>
                <option value="476">Amtsgericht Ribnitz-Damgarten</option>
                <option value="477">Amtsgericht Riedlingen</option>
                <option value="478">Amtsgericht Riesa</option>
                <option value="479">Amtsgericht Rinteln</option>
                <option value="480">Amtsgericht Rockenhausen</option>
                <option value="481">Amtsgericht Rosenheim</option>
                <option value="482">Amtsgericht Rostock</option>
                <option value="483">Amtsgericht Rotenburg (Wiimme)</option>
                <option value="484">Amtsgericht Rottenburg</option>
                <option value="485">Amtsgericht Rottweil</option>
                <option value="486">Amtsgericht Rudesheim am Rhein</option>
                <option value="487">Amtsgericht Rudolstadt</option>
                <option value="488">Amtsgericht Russelsheim</option>
                <option value="489">Amtsgericht Saarbrücken</option>
                <option value="490">Amtsgericht Saarburg</option>
                <option value="491">Amtsgericht Saarlouis</option>
                <option value="492">Amtsgericht Salzgitter</option>
                <option value="493">Amtsgericht Salzwedel</option>
                <option value="494">Amtsgericht Sangerhausen</option>
                <option value="495">Amtsgericht Sankt Blasien</option>
                <option value="496">Amtsgericht Sankt Goar</option>
                <option value="497">Amtsgericht Sankt Ingbert</option>
                <option value="498">Amtsgericht Schleiden</option>
                <option value="499">Amtsgericht Schleswig</option>
                <option value="500">Amtsgericht Schmallenberg</option>
                <option value="501">Amtsgericht Schopfheim</option>
                <option value="502">Amtsgericht Schorndorf</option>
                <option value="503">Amtsgericht Schwabach</option>
                <option value="504">Amtsgericht Schwalmstadt</option>
                <option value="505">Amtsgericht Schwandorf</option>
                <option value="506">Amtsgericht Schwarzenbek</option>
                <option value="507">Amtsgericht Schwedt/Oder</option>
                <option value="508">Amtsgericht Schweinfurt</option>
                <option value="509">Amtsgericht Schwelm</option>
                <option value="510">Amtsgericht Schwerin</option>
                <option value="511">Amtsgericht Schwerte</option>
                <option value="512">Amtsgericht Schwetzingen</option>
                <option value="513">Amtsgericht Schwäbisch Gmünd</option>
                <option value="514">Amtsgericht Schwäbisch Hall</option>
                <option value="515">Amtsgericht Schönau im Schwarzwald</option>
                <option value="516">Amtsgericht Schönebeck</option>
                <option value="517">Amtsgericht Schöneberg</option>
                <option value="518">Amtsgericht Seesen</option>
                <option value="519">Amtsgericht Seligenstadt</option>
                <option value="520">Amtsgericht Senftenberg</option>
                <option value="521">Amtsgericht Siegburg</option>
                <option value="522">Amtsgericht Siegen</option>
                <option value="523">Amtsgericht Sigmaringen</option>
                <option value="524">Amtsgericht Simmem (Hunsrück)</option>
                <option value="525">Amtsgericht Singen am Hohentwiel</option>
                <option value="526">Amtsgericht Sinsheim</option>
                <option value="527">Amtsgericht Sinzig</option>
                <option value="528">Amtsgericht Soest</option>
                <option value="529">Amtsgericht Solingen</option>
                <option value="530">Amtsgericht Soltau</option>
                <option value="531">Amtsgericht Sondershausen</option>
                <option value="532">Amtsgericht Sonneberg</option>
                <option value="533">Amtsgericht Sonthofen</option>
                <option value="534">Amtsgericht Spaichingen</option>
                <option value="535">Amtsgericht Spandau</option>
                <option value="536">Amtsgericht Speyer</option>
                <option value="537">Amtsgericht Springe</option>
                <option value="538">Amtsgericht St. Wendel</option>
                <option value="539">Amtsgericht Stade</option>
                <option value="540">Amtsgericht Stadthagen</option>
                <option value="541">Amtsgericht Stadtroda</option>
                <option value="542">Amtsgericht Starnberg</option>
                <option value="543">Amtsgericht Staufen im Breisgau</option>
                <option value="544">Amtsgericht Steinfurt</option>
                <option value="545">Amtsgericht Stendal</option>
                <option value="546">Amtsgericht Stockach</option>
                <option value="547">Amtsgericht Stolzenau</option>
                <option value="548">Amtsgericht Stralsund</option>
                <option value="549">Amtsgericht Straubing</option>
                <option value="550">Amtsgericht Strausberg</option>
                <option value="551">Amtsgericht Stuttgart</option>
                <option value="552">Amtsgericht Stuttgart-Bad Cannstatt</option>
                <option value="553">Amtsgericht Suhl</option>
                <option value="554">Amtsgericht Sulingen</option>
                <option value="555">Amtsgericht Syke</option>
                <option value="556">Amtsgericht Sömmerda</option>
                <option value="557">Amtsgericht Tauberbischofsheim</option>
                <option value="558">Amtsgericht Tecklenburg</option>
                <option value="559">Amtsgericht Tempelhof-Kreuzberg</option>
                <option value="560">Amtsgericht Tettnang</option>
                <option value="561">Amtsgericht Tiergarten</option>
                <option value="562">Amtsgericht Tirschenreuth</option>
                <option value="563">Amtsgericht Titisee-Neustadt</option>
                <option value="564">Amtsgericht Torgau</option>
                <option value="565">Amtsgericht Tostedt</option>
                <option value="566">Amtsgericht Traunstein</option>
                <option value="567">Amtsgericht Trier</option>
                <option value="568">Amtsgericht Tuttlingen</option>
                <option value="569">Amtsgericht Tübingen</option>
                <option value="570">Amtsgericht Uelzen</option>
                <option value="571">Amtsgericht Ulm</option>
                <option value="572">Amtsgericht Unna</option>
                <option value="573">Amtsgericht Vaihingen an der Enz</option>
                <option value="574">Amtsgericht Varel</option>
                <option value="575">Amtsgericht Vechta</option>
                <option value="576">Amtsgericht Velbert</option>
                <option value="577">Amtsgericht Verden (Aller)</option>
                <option value="578">Amtsgericht Viechtach</option>
                <option value="579">Amtsgericht Viersen</option>
                <option value="580">Amtsgericht Villingen-Schwenningen</option>
                <option value="581">Amtsgericht Völklingen</option>
                <option value="582">Amtsgericht Waiblingen</option>
                <option value="583">Amtsgericht Waldbröi</option>
                <option value="584">Amtsgericht Waldkirch</option>
                <option value="585">Amtsgericht Waldshut-Tiengen</option>
                <option value="586">Amtsgericht Walsrode</option>
                <option value="587">Amtsgericht Wangen im Allgäu</option>
                <option value="588">Amtsgericht Warburg</option>
                <option value="589">Amtsgericht Waren (MQritz)</option>
                <option value="590">Amtsgericht Warendorf</option>
                <option value="591">Amtsgericht Warstein</option>
                <option value="592">Amtsgericht Wedding</option>
                <option value="593">Amtsgericht Weiden i.d. OPf. .</option>
                <option value="594">Amtsgericht Weilburg</option>
                <option value="595">Amtsgericht Weilheim i.OB</option>
                <option value="596">Amtsgericht Weimar</option>
                <option value="597">Amtsgericht Weinheim</option>
                <option value="598">Amtsgericht Weißenburg</option>
                <option value="599">Amtsgericht Weißenfels</option>
                <option value="600">Amtsgericht Weißwasser</option>
                <option value="601">Amtsgericht Wennigsen (Deister)</option>
                <option value="602">Amtsgericht Werl</option>
                <option value="603">Amtsgericht Wermelskirchen</option>
                <option value="604">Amtsgericht Wernigerode</option>
                <option value="605">Amtsgericht Wertheim</option>
                <option value="606">Amtsgericht Wesel</option>
                <option value="607">Amtsgericht Westerburg</option>
                <option value="608">Amtsgericht Westerstede</option>
                <option value="609">Amtsgericht Wetter</option>
                <option value="610">Amtsgericht Wetzlar</option>
                <option value="611">Amtsgericht Wiesbaden</option>
                <option value="612">Amtsgericht Wiesloch</option>
                <option value="613">Amtsgericht Wildeshausen</option>
                <option value="614">Amtsgericht Wilhelmshaven</option>
                <option value="615">Amtsgericht Winsen (Luhe)</option>
                <option value="616">Amtsgericht Wipperfürth</option>
                <option value="617">Amtsgericht Wismar</option>
                <option value="618">Amtsgericht Witten</option>
                <option value="619">Amtsgericht Wittenberg</option>
                <option value="620">Amtsgericht Wittlich</option>
                <option value="621">Amtsgericht Wittmund</option>
                <option value="622">Amtsgericht Wolfach</option>
                <option value="623">Amtsgericht Wolfenbuttel</option>
                <option value="624">Amtsgericht Wolfratshausen</option>
                <option value="625">Amtsgericht Wolfsburg</option>
                <option value="626">Amtsgericht Worms</option>
                <option value="627">Amtsgericht Wunsiedel</option>
                <option value="628">Amtsgericht Wuppertal</option>
                <option value="629">Amtsgericht Würzburg</option>
                <option value="630">Amtsgericht Überlingen</option>
                <option value="631">Amtsgericht Zehdenick</option>
                <option value="632">Amtsgericht Zeitz</option>
                <option value="633">Amtsgericht Zerbst</option>
                <option value="634">Amtsgericht Zeven</option>
                <option value="635">Amtsgericht Zittau</option>
                <option value="636">Amtsgericht Zossen</option>
                <option value="637">Amtsgericht Zweibrücken</option>
                <option value="638">Amtsgericht Zwickau</option>
                <option value="639">Amtsgericht Öhringen</option>
                <option value="640">Anwaltsgericht Kammerbezirk Düsseldorf</option>
                <option value="641">Anwaltsgericht Kammerbezirk Hamm</option>
                <option value="642">Anwaltsgericht Kammerbezirk Köln</option>
                <option value="643">Anwaltsgerichtshof NRW</option>
                <option value="644">Arbeitsgericht Aachen</option>
                <option value="645">Arbeitsgericht Arnsberg</option>
                <option value="646">Arbeitsgericht Augsburg</option>
                <option value="647">Arbeitsgericht Bamberg</option>
                <option value="648">Arbeitsgericht Bautzen</option>
                <option value="649">Arbeitsgericht Bayreuth</option>
                <option value="650">Arbeitsgericht Berlin</option>
                <option value="651">Arbeitsgericht Bielefeld</option>
                <option value="652">Arbeitsgericht Bocholt</option>
                <option value="653">Arbeitsgericht Bochum</option>
                <option value="654">Arbeitsgericht Bonn</option>
                <option value="655">Arbeitsgericht Brandenburg an der Havel</option>
                <option value="656">Arbeitsgericht Braunschweig</option>
                <option value="657">Arbeitsgericht Bremen - Bremerhaven</option>
                <option value="658">Arbeitsgericht Celle</option>
                <option value="659">Arbeitsgericht Chemnitz</option>
                <option value="660">Arbeitsgericht Cottbus</option>
                <option value="661">Arbeitsgericht Darmstadt</option>
                <option value="662">Arbeitsgericht Dessau-Roßlau</option>
                <option value="663">Arbeitsgericht Detmold</option>
                <option value="664">Arbeitsgericht Dortmund</option>
                <option value="665">Arbeitsgericht Dresden</option>
                <option value="666">Arbeitsgericht Duisburg</option>
                <option value="667">Arbeitsgericht Düsseldorf</option>
                <option value="668">Arbeitsgericht Eberswalde</option>
                <option value="669">Arbeitsgericht Elmshorn</option>
                <option value="670">Arbeitsgericht Emden</option>
                <option value="671">Arbeitsgericht Erfurt</option>
                <option value="672">Arbeitsgericht Essen</option>
                <option value="673">Arbeitsgericht Flensburg</option>
                <option value="674">Arbeitsgericht Frankfurt (Oder)</option>
                <option value="675">Arbeitsgericht Frankfurt am Main</option>
                <option value="676">Arbeitsgericht Freiburg</option>
                <option value="677">Arbeitsgericht Fulda</option>
                <option value="678">Arbeitsgericht Gelsenkirchen</option>
                <option value="679">Arbeitsgericht Gera</option>
                <option value="680">Arbeitsgericht GieBen</option>
                <option value="681">Arbeitsgericht Göttingen</option>
                <option value="682">Arbeitsgericht Hagen</option>
                <option value="683">Arbeitsgericht Halle</option>
                <option value="684">Arbeitsgericht Hamburg</option>
                <option value="685">Arbeitsgericht Hameln</option>
                <option value="686">Arbeitsgericht Hamm</option>
                <option value="687">Arbeitsgericht Hannover</option>
                <option value="688">Arbeitsgericht Heilbronn</option>
                <option value="689">Arbeitsgericht Herford</option>
                <option value="690">Arbeitsgericht Herne</option>
                <option value="691">Arbeitsgericht Hildesheim</option>
                <option value="692">Arbeitsgericht Iserlohn</option>
                <option value="693">Arbeitsgericht Kaiserslautern</option>
                <option value="694">Arbeitsgericht Karlsruhe</option>
                <option value="695">Arbeitsgericht Kassel</option>
                <option value="696">Arbeitsgericht Kempten</option>
                <option value="697">Arbeitsgericht Kiel</option>
                <option value="698">Arbeitsgericht Koblenz</option>
                <option value="699">Arbeitsgericht Krefeld</option>
                <option value="700">Arbeitsgericht Köln</option>
                <option value="701">Arbeitsgericht Leipzig</option>
                <option value="702">Arbeitsgericht Lingen (Ems)</option>
                <option value="703">Arbeitsgericht Ludwigshafen am Rhein</option>
                <option value="704">Arbeitsgericht Luebeck</option>
                <option value="705">Arbeitsgericht Lüneburg</option>
                <option value="706">Arbeitsgericht Lörrach</option>
                <option value="707">Arbeitsgericht Magdeburg</option>
                <option value="708">Arbeitsgericht Mainz</option>
                <option value="709">Arbeitsgericht Mannheim</option>
                <option value="710">Arbeitsgericht Minden</option>
                <option value="711">Arbeitsgericht München</option>
                <option value="712">Arbeitsgericht Münster</option>
                <option value="713">Arbeitsgericht Mönchengladbach</option>
                <option value="714">Arbeitsgericht Neumünster</option>
                <option value="715">Arbeitsgericht Neunkirchen</option>
                <option value="716">Arbeitsgericht Neuruppin</option>
                <option value="717">Arbeitsgericht Nienburg (Weser)</option>
                <option value="718">Arbeitsgericht Nordhausen</option>
                <option value="719">Arbeitsgericht Nürnberg</option>
                <option value="720">Arbeitsgericht Oberhausen</option>
                <option value="721">Arbeitsgericht Offenbach am Main</option>
                <option value="722">Arbeitsgericht Oldenburg (Oldenburg)</option>
                <option value="723">Arbeitsgericht Osnabrück</option>
                <option value="724">Arbeitsgericht Paderborn</option>
                <option value="725">Arbeitsgericht Passau</option>
                <option value="726">Arbeitsgericht Pforzheim</option>
                <option value="727">Arbeitsgericht Potsdam</option>
                <option value="728">Arbeitsgericht Regensburg</option>
                <option value="729">Arbeitsgericht Reutlingen</option>
                <option value="730">Arbeitsgericht Rheine</option>
                <option value="731">Arbeitsgericht Rosenheim</option>
                <option value="732">Arbeitsgericht Rostock</option>
                <option value="733">Arbeitsgericht Saarbrücken</option>
                <option value="734">Arbeitsgericht Saarlouis</option>
                <option value="735">Arbeitsgericht Schwerin</option>
                <option value="736">Arbeitsgericht Siegburg</option>
                <option value="737">Arbeitsgericht Siegen</option>
                <option value="738">Arbeitsgericht Solingen</option>
                <option value="739">Arbeitsgericht Stade</option>
                <option value="740">Arbeitsgericht Stendal</option>
                <option value="741">Arbeitsgericht Stralsund</option>
                <option value="742">Arbeitsgericht Stuttgart</option>
                <option value="743">Arbeitsgericht Suhl</option>
                <option value="744">Arbeitsgericht Trier</option>
                <option value="745">Arbeitsgericht Ulm</option>
                <option value="746">Arbeitsgericht Verden (Aller)</option>
                <option value="747">Arbeitsgericht Weiden</option>
                <option value="748">Arbeitsgericht Wesel</option>
                <option value="749">Arbeitsgericht Wiesbaden</option>
                <option value="750">Arbeitsgericht Wilhelmshaven</option>
                <option value="751">Arbeitsgericht Wuppertal</option>
                <option value="752">Arbeitsgericht Würzburg</option>
                <option value="753">Arbeitsgericht Zwickau</option>
                <option value="754">Bayerischer Verfassungsgerichtshof</option>
                <option value="755">Bayerischer Verwaltungsgerichtshof</option>
                <option value="756">Bayerisches Landessozialgericht</option>
                <option value="757">Bayerisches Oberstes Landesgericht</option>
                <option value="758">Berufsgericht für Architekten, Architektinnen, Stadtplaner und Stadtpla-nerinnen</option>
                <option value="759">Berufsgericht für Beratende Ingenieure und Ingenieurinnen sowie Inge-nieure und Ingenieurinnen im Bauwesen</option>
                <option value="760">Berufsgericht für Heilberufe Köln</option>
                <option value="761">Berufsgericht für Heilberufe Münster</option>
                <option value="762">Brandenburgisches Oberlandesgericht</option>
                <option value="763">Bundesarbeitsgericht</option>
                <option value="764">Bundesfinanzhof</option>
                <option value="765">Bundesgerichtshof</option>
                <option value="766">Bundespatentgericht</option>
                <option value="767">Bundessozialgericht</option>
                <option value="768">Bundesverfassungsgericht</option>
                <option value="769">Bundesverwaltungsgericht</option>
                <option value="770">Dienstgericht für Richter</option>
                <option value="771">Dienstgerichtshof für Richter</option>
                <option value="772">Europäischer Gerichtshof</option>
                <option value="773">Finanzgericht Baden-Württemberg</option>
                <option value="774">Finanzgericht Berlin-Brandenburg</option>
                <option value="775">Finanzgericht Bremen</option>
                <option value="776">Finanzgericht des Landes Sachsen-Anhalt</option>
                <option value="777">Finanzgericht des Saarlandes</option>
                <option value="778">Finanzgericht Düsseldorf</option>
                <option value="779">Finanzgericht Hamburg</option>
                <option value="780">Finanzgericht Köln</option>
                <option value="781">Finanzgericht Mecklenburg-Vorpommern</option>
                <option value="782">Finanzgericht München</option>
                <option value="783">Finanzgericht Münster</option>
                <option value="784">Finanzgericht Nürnberg</option>
                <option value="785">Finanzgericht Rheinland-Pfalz</option>
                <option value="786">Hamburgisches Oberverwaltungsgericht</option>
                <option value="787">Hamburgisches Verfassungsgericht</option>
                <option value="788">Hanseatisches Oberlandesgericht</option>
                <option value="789">Hanseatisches Oberlandesgericht</option>
                <option value="790">Hessischer Verwaltungsgerichtshof</option>
                <option value="791">Hessisches Finanzgericht</option>
                <option value="792">Hessisches Landesarbeitsgericht</option>
                <option value="793">Hessisches Landessozialgericht</option>
                <option value="794">Kammergericht</option>
                <option value="795">Landesarbeitsgericht Baden-Württemberg</option>
                <option value="796">Landesarbeitsgericht Berlin-Brandenburg</option>
                <option value="797">Landesarbeitsgericht Bremen</option>
                <option value="798">Landesarbeitsgericht Düsseldorf</option>
                <option value="799">Landesarbeitsgericht Hamburg</option>
                <option value="800">Landesarbeitsgericht Hamm</option>
                <option value="801">Landesarbeitsgericht Köln</option>
                <option value="802">Landesarbeitsgericht Mecklenburg-Vorpommern</option>
                <option value="803">Landesarbeitsgericht München</option>
                <option value="804">Landesarbeitsgericht Niedersachsen</option>
                <option value="805">Landesarbeitsgericht Nürnberg</option>
                <option value="806">Landesarbeitsgericht Rheinland-Pfalz</option>
                <option value="807">Landesarbeitsgericht Saarland Saarbrücken</option>
                <option value="808">Landesarbeitsgericht Sachsen-Anhalt</option>
                <option value="809">Landesarbeitsgericht Schleswig-Holstein</option>
                <option value="810">Landesberufsgericht für Architekten, Architektinnen, Stadtplaner und Stadtplanerinnen</option>
                <option value="811">Landesberufsgericht für Beratende Ingenieure und Ingenieurinnen sowie Ingenieure und Ingenieurinnen im Bauwesen</option>
                <option value="812">Landesberufsgericht für Heilberufe</option>
                <option value="813">Landessozialgericht Baden-Württemberg</option>
                <option value="814">Landessozialgericht Berlin-Brandenburg</option>
                <option value="815">Landessozialgericht für das Saarland</option>
                <option value="816">Landessozialgericht Hamburg</option>
                <option value="817">Landessozialgericht Mecklenburg-Vorpommern</option>
                <option value="818">Landessozialgericht Niedersachsen-Bremen</option>
                <option value="819">Landessozialgericht NRW</option>
                <option value="820">Landessozialgericht Rheinland-Pfalz</option>
                <option value="821">Landessozialgericht Sachsen-Anhalt</option>
                <option value="822">Landesverfassungsgericht Mecklenburg-Vorpommern</option>
                <option value="823">Landesverfassungsgericht Sachsen-Anhalt</option>
                <option value="824">Landgericht Aachen</option>
                <option value="825">Landgericht Amberg</option>
                <option value="826">Landgericht Ansbach</option>
                <option value="827">Landgericht Arnsberg</option>
                <option value="828">Landgericht Aschaffenburg</option>
                <option value="829">Landgericht Augsburg</option>
                <option value="830">Landgericht Aurich</option>
                <option value="831">Landgericht Bad Kreuznach</option>
                <option value="832">Landgericht Baden-Baden</option>
                <option value="833">Landgericht Bamberg</option>
                <option value="834">Landgericht Bayreuth</option>
                <option value="835">Landgericht Berlin</option>
                <option value="836">Landgericht Bielefeld</option>
                <option value="837">Landgericht Bochum</option>
                <option value="838">Landgericht Bonn</option>
                <option value="839">Landgericht Braunschweig</option>
                <option value="840">Landgericht Bremen</option>
                <option value="841">Landgericht Bückeburg</option>
                <option value="842">Landgericht Chemnitz</option>
                <option value="843">Landgericht Coburg</option>
                <option value="844">Landgericht Cottbus</option>
                <option value="845">Landgericht Darmstadt</option>
                <option value="846">Landgericht Deggendorf</option>
                <option value="847">Landgericht Dessau-Roßlau</option>
                <option value="848">Landgericht Detmold</option>
                <option value="849">Landgericht Dortmund</option>
                <option value="850">Landgericht Dresden</option>
                <option value="851">Landgericht Duisburg</option>
                <option value="852">Landgericht Düsseldorf</option>
                <option value="853">Landgericht Ellwangen</option>
                <option value="854">Landgericht Erfurt</option>
                <option value="855">Landgericht Essen</option>
                <option value="856">Landgericht Flensburg</option>
                <option value="857">Landgericht Frankenthal (Pfalz)</option>
                <option value="858">Landgericht Frankfurt (Oder)</option>
                <option value="859">Landgericht Frankfurt am Main</option>
                <option value="860">Landgericht Freiburg</option>
                <option value="861">Landgericht Fulda</option>
                <option value="862">Landgericht Gera</option>
                <option value="863">Landgericht GieBen</option>
                <option value="864">Landgericht Görlitz</option>
                <option value="865">Landgericht Göttingen</option>
                <option value="866">Landgericht Hagen</option>
                <option value="867">Landgericht Halle</option>
                <option value="868">Landgericht Hamburg</option>
                <option value="869">Landgericht Hanau</option>
                <option value="870">Landgericht Hannover</option>
                <option value="871">Landgericht Hechingen</option>
                <option value="872">Landgericht Heidelberg</option>
                <option value="873">Landgericht Heilbronn</option>
                <option value="874">Landgericht Hildesheim</option>
                <option value="875">Landgericht Hof</option>
                <option value="876">Landgericht Ingolstadt</option>
                <option value="877">Landgericht Itzehoe</option>
                <option value="878">Landgericht Kaiserslautern</option>
                <option value="879">Landgericht Karlsruhe</option>
                <option value="880">Landgericht Kassel</option>
                <option value="881">Landgericht Kempten (Allgäu)</option>
                <option value="882">Landgericht Kiel</option>
                <option value="883">Landgericht Kleve</option>
                <option value="884">Landgericht Koblenz</option>
                <option value="885">Landgericht Konstanz</option>
                <option value="886">Landgericht Krefeld</option>
                <option value="887">Landgericht Köln</option>
                <option value="888">Landgericht Landau in der Pfalz</option>
                <option value="889">Landgericht Landshut</option>
                <option value="890">Landgericht Leipzig</option>
                <option value="891">Landgericht Limburg a.d. Lahn</option>
                <option value="892">Landgericht Lübeck</option>
                <option value="893">Landgericht Lüneburg</option>
                <option value="894">Landgericht Magdeburg</option>
                <option value="895">Landgericht Mainz</option>
                <option value="896">Landgericht Mannheim</option>
                <option value="897">Landgericht Marburg</option>
                <option value="898">Landgericht Meiningen</option>
                <option value="899">Landgericht Memmingen</option>
                <option value="900">Landgericht Mosbach</option>
                <option value="901">Landgericht Mühlhausen</option>
                <option value="902">Landgericht München 1</option>
                <option value="903">Landgericht München II</option>
                <option value="904">Landgericht Münster</option>
                <option value="905">Landgericht Mönchengladbach</option>
                <option value="906">Landgericht Neubrandenburg</option>
                <option value="907">Landgericht Neuruppin</option>
                <option value="908">Landgericht Nürnberg-Fürth</option>
                <option value="909">Landgericht Offenburg</option>
                <option value="910">Landgericht Oldenburg (Oldenburg)</option>
                <option value="911">Landgericht Osnabrück</option>
                <option value="912">Landgericht Paderborn</option>
                <option value="913">Landgericht Passau</option>
                <option value="914">Landgericht Potsdam</option>
                <option value="915">Landgericht Ravensburg</option>
                <option value="916">Landgericht Regensburg</option>
                <option value="917">Landgericht Rostock</option>
                <option value="918">Landgericht Rottweil</option>
                <option value="919">Landgericht Saarbrücken</option>
                <option value="920">Landgericht Schweinfurt</option>
                <option value="921">Landgericht Schwerin</option>
                <option value="922">Landgericht Siegen</option>
                <option value="923">Landgericht Stade</option>
                <option value="924">Landgericht Stendal</option>
                <option value="925">Landgericht Stralsund</option>
                <option value="926">Landgericht Stuttgart</option>
                <option value="927">Landgericht Traunstein</option>
                <option value="928">Landgericht Trier</option>
                <option value="929">Landgericht Tübingen</option>
                <option value="930">Landgericht Ulm</option>
                <option value="931">Landgericht Verden (Aller)</option>
                <option value="932">Landgericht Waldshut-Tiengen</option>
                <option value="933">Landgericht Weiden</option>
                <option value="934">Landgericht Wiesbaden</option>
                <option value="935">Landgericht Wuppertal</option>
                <option value="936">Landgericht Würzburg</option>
                <option value="937">Landgericht Zweibrücken</option>
                <option value="938">Landgericht Zwickau</option>
                <option value="939">Moselschifffahrtsobergericht Köln</option>
                <option value="940">Niedersächsischer Staatsgerichtshof</option>
                <option value="941">Niedersächsisches Finanzgericht</option>
                <option value="942">Niedersächsisches Oberverwaltungsgericht</option>
                <option value="943">Oberlandesgericht Bamberg</option>
                <option value="944">Oberlandesgericht Braunschweig</option>
                <option value="945">Oberlandesgericht Celle</option>
                <option value="946">Oberlandesgericht Dresden</option>
                <option value="947">Oberlandesgericht Düsseldorf</option>
                <option value="948">Oberlandesgericht Frankfurt am Main</option>
                <option value="949">Oberlandesgericht Hamm</option>
                <option value="950">Oberlandesgericht Karlsruhe</option>
                <option value="951">Oberlandesgericht Koblenz</option>
                <option value="952">Oberlandesgericht Köln</option>
                <option value="953">Oberlandesgericht München</option>
                <option value="954">Oberlandesgericht Naumburg</option>
                <option value="955">Oberlandesgericht Nürnberg</option>
                <option value="956">Oberlandesgericht Oldenburg</option>
                <option value="957">Oberlandesgericht Rostock</option>
                <option value="958">Oberlandesgericht Stuttgart</option>
                <option value="959">Oberverwaltungsgericht Berlin-Brandenburg</option>
                <option value="960">Oberverwaltungsgericht Bremen</option>
                <option value="961">Oberverwaltungsgericht des Landes Sachsen-Anhalt</option>
                <option value="962">Oberverwaltungsgericht des Saarlandes</option>
                <option value="963">Oberverwaltungsgericht Mecklenburg-Vorpommern</option>
                <option value="964">Oberverwaltungsgericht Nordrhein-Westfalen</option>
                <option value="965">Oberverwaltungsgericht Rheinland-Pfalz</option>
                <option value="966">Pfälzisches Oberlandesgericht Zweibrücken</option>
                <option value="967">Rheinschifffahrtsgericht Duisburg-Ruhrort</option>
                <option value="968">Rheinschifffahrtsobergericht Köln</option>
                <option value="969">Saarländisches Oberlandesgericht Saarbrücken</option>
                <option value="970">Schifffahrtsgericht Dortmund</option>
                <option value="971">Schifffahrtsgericht Duisburg-Ruhrort</option>
                <option value="972">Schifffahrtsgericht Minden</option>
                <option value="973">Schifffahrtsobergericht Hamm</option>
                <option value="974">Schifffahrtsobergericht Köln</option>
                <option value="975">Schleswig Holsteinisches Oberverwaltungsgericht</option>
                <option value="976">Schleswig-Holsteinisches Finanzgericht</option>
                <option value="977">Schleswig-Holsteinisches Landessozialgericht</option>
                <option value="978">Schleswig-Holsteinisches Landesverfassungsgericht</option>
                <option value="979">Schleswig-Holsteinisches Oberlandesgericht</option>
                <option value="980">Schleswig-Holsteinisches Verwaltungsgericht</option>
                <option value="981">Sozialgericht Aachen</option>
                <option value="982">Sozialgericht Altenburg</option>
                <option value="983">Sozialgericht Augsburg</option>
                <option value="984">Sozialgericht Aurich</option>
                <option value="985">Sozialgericht Bayreuth</option>
                <option value="986">Sozialgericht Berlin</option>
                <option value="987">Sozialgericht Braunschweig</option>
                <option value="988">Sozialgericht Bremen</option>
                <option value="989">Sozialgericht Chemnitz</option>
                <option value="990">Sozialgericht Cottbus</option>
                <option value="991">Sozialgericht Darmstadt</option>
                <option value="992">Sozialgericht Dessau-Roßlau</option>
                <option value="993">Sozialgericht Detmold</option>
                <option value="994">Sozialgericht Dortmund</option>
                <option value="995">Sozialgericht Dresden</option>
                <option value="996">Sozialgericht Duisburg</option>
                <option value="997">Sozialgericht Düsseldorf</option>
                <option value="998">Sozialgericht Frankfurt (Oder)</option>
                <option value="999">Sozialgericht Frankfurt am Main</option>
                <option value="1000">Sozialgericht Freiburg</option>
                <option value="1001">Sozialgericht Fulda</option>
                <option value="1002">Sozialgericht für das Saarland</option>
                <option value="1003">Sozialgericht Gelsenkirchen</option>
                <option value="1004">Sozialgericht GieBen</option>
                <option value="1005">Sozialgericht Gotha</option>
                <option value="1006">Sozialgericht Halle</option>
                <option value="1007">Sozialgericht Hamburg</option>
                <option value="1008">Sozialgericht Hannover</option>
                <option value="1009">Sozialgericht Heilbronn</option>
                <option value="1010">Sozialgericht Hildesheim</option>
                <option value="1011">Sozialgericht Itzehoe</option>
                <option value="1012">Sozialgericht Karlsruhe</option>
                <option value="1013">Sozialgericht Kassel</option>
                <option value="1014">Sozialgericht Kiel</option>
                <option value="1015">Sozialgericht Koblenz</option>
                <option value="1016">Sozialgericht Konstanz</option>
                <option value="1017">Sozialgericht Köln</option>
                <option value="1018">Sozialgericht Landshut</option>
                <option value="1019">Sozialgericht Leipzig</option>
                <option value="1020">Sozialgericht Luebeck</option>
                <option value="1021">Sozialgericht Lüneburg</option>
                <option value="1022">Sozialgericht Magdeburg</option>
                <option value="1023">Sozialgericht Mainz</option>
                <option value="1024">Sozialgericht Mannheim</option>
                <option value="1025">Sozialgericht Marburg</option>
                <option value="1026">Sozialgericht Meiningen</option>
                <option value="1027">Sozialgericht München</option>
                <option value="1028">Sozialgericht Münster</option>
                <option value="1029">Sozialgericht Neubrandenburg</option>
                <option value="1030">Sozialgericht Neuruppin</option>
                <option value="1031">Sozialgericht Nordhausen</option>
                <option value="1032">Sozialgericht Nürnberg</option>
                <option value="1033">Sozialgericht Oldenburg (Oldenburg)</option>
                <option value="1034">Sozialgericht Osnabrück</option>
                <option value="1035">Sozialgericht Potsdam</option>
                <option value="1036">Sozialgericht Regensburg</option>
                <option value="1037">Sozialgericht Reutlingen</option>
                <option value="1038">Sozialgericht Rostock</option>
                <option value="1039">Sozialgericht Schleswig</option>
                <option value="1040">Sozialgericht Schwerin</option>
                <option value="1041">Sozialgericht Speyer</option>
                <option value="1042">Sozialgericht Stade</option>
                <option value="1043">Sozialgericht Stralsund</option>
                <option value="1044">Sozialgericht Stuttgart</option>
                <option value="1045">Sozialgericht Trier</option>
                <option value="1046">Sozialgericht Ulm</option>
                <option value="1047">Sozialgericht Wiesbaden</option>
                <option value="1048">Sozialgericht Würzburg</option>
                <option value="1049">Staatsgerichtshof Bremen</option>
                <option value="1050">Staatsgerichtshof des Landes Hessen</option>
                <option value="1051">Sächsisches Finanzgericht</option>
                <option value="1052">Sächsisches Landesarbeitsgericht</option>
                <option value="1053">Sächsisches Landessozialgericht</option>
                <option value="1054">Sächsisches Oberverwaltungsgericht</option>
                <option value="1055">Thüringer Finanzgericht</option>
                <option value="1056">Thüringer Landesarbeitsgericht</option>
                <option value="1057">Thüringer Landessozialgericht</option>
                <option value="1058">Thüringer Oberlandesgericht</option>
                <option value="1059">Thüringer Oberverwaltungsgericht</option>
                <option value="1060">Thüringer Verfassungsgerichtshof</option>
                <option value="1061">Unknown court</option>
                <option value="1062">Verfassungsgericht des Landes Brandenburg</option>
                <option value="1063">Verfassungsgerichtshof</option>
                <option value="1064">Verfassungsgerichtshof Baden-Württemberg</option>
                <option value="1065">Verfassungsgerichtshof des Freistaates Sachsen</option>
                <option value="1066">Verfassungsgerichtshof des Landes Berlin</option>
                <option value="1067">Verfassungsgerichtshof des Saarlandes</option>
                <option value="1068">Verfassungsgerichtshof Rheinland-Pfalz</option>
                <option value="1069">Verwaltungsgericht Aachen</option>
                <option value="1070">Verwaltungsgericht Ansbach</option>
                <option value="1071">Verwaltungsgericht Arnsberg</option>
                <option value="1072">Verwaltungsgericht Augsburg</option>
                <option value="1073">Verwaltungsgericht Bayreuth</option>
                <option value="1074">Verwaltungsgericht Berlin</option>
                <option value="1075">Verwaltungsgericht Braunschweig</option>
                <option value="1076">Verwaltungsgericht Bremen</option>
                <option value="1077">Verwaltungsgericht Chemnitz</option>
                <option value="1078">Verwaltungsgericht Cottbus</option>
                <option value="1079">Verwaltungsgericht Darmstadt</option>
                <option value="1080">Verwaltungsgericht des Saarlandes Saarlouis</option>
                <option value="1081">Verwaltungsgericht Dresden</option>
                <option value="1082">Verwaltungsgericht Düsseldorf</option>
                <option value="1083">Verwaltungsgericht Frankfurt (Oder)</option>
                <option value="1084">Verwaltungsgericht Frankfurt am Main</option>
                <option value="1085">Verwaltungsgericht Freiburg</option>
                <option value="1086">Verwaltungsgericht Gelsenkirchen</option>
                <option value="1087">Verwaltungsgericht Gera</option>
                <option value="1088">Verwaltungsgericht Gießen</option>
                <option value="1089">Verwaltungsgericht Greifswald</option>
                <option value="1090">Verwaltungsgericht Göttingen</option>
                <option value="1091">Verwaltungsgericht Halle</option>
                <option value="1092">Verwaltungsgericht Hamburg</option>
                <option value="1093">Verwaltungsgericht Hannover</option>
                <option value="1094">Verwaltungsgericht Karlsruhe</option>
                <option value="1095">Verwaltungsgericht Kassel</option>
                <option value="1096">Verwaltungsgericht Koblenz</option>
                <option value="1097">Verwaltungsgericht Köln</option>
                <option value="1098">Verwaltungsgericht Leipzig</option>
                <option value="1099">Verwaltungsgericht Lüneburg</option>
                <option value="1100">Verwaltungsgericht Magdeburg</option>
                <option value="1101">Verwaltungsgericht Mainz</option>
                <option value="1102">Verwaltungsgericht Meiningen</option>
                <option value="1103">Verwaltungsgericht Minden</option>
                <option value="1104">Verwaltungsgericht München</option>
                <option value="1105">Verwaltungsgericht Münster</option>
                <option value="1106">Verwaltungsgericht Neustadt an der Weinstraße</option>
                <option value="1107">Verwaltungsgericht Oldenburg (Oldenburg)</option>
                <option value="1108">Verwaltungsgericht Osnabrück</option>
                <option value="1109">Verwaltungsgericht Potsdam</option>
                <option value="1110">Verwaltungsgericht Regensburg</option>
                <option value="1111">Verwaltungsgericht Schwerin</option>
                <option value="1112">Verwaltungsgericht Sigmaringen</option>
                <option value="1113">Verwaltungsgericht Stade</option>
                <option value="1114">Verwaltungsgericht Stuttgart</option>
                <option value="1115">Verwaltungsgericht Trier</option>
                <option value="1116">Verwaltungsgericht Weimar</option>
                <option value="1117">Verwaltungsgericht Wiesbaden</option>
                <option value="1118">Verwaltungsgericht Würzburg</option>
                <option value="1119">Verwaltungsgerichtshof Baden-Württemberg</option>
            </select>
            <label for="company_court_id">Court</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_trade_registration_number" name="company_trade_registration_number" class="form-control" v-model="user.trade_registration_number">
            <label for="company_trade_registration_number">Trade registration number</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_tax_number" name="company_tax_number" class="form-control" v-model="user.tax_number">
            <label for="company_tax_number">Tax number</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_street" name="company_street" class="form-control" v-model="user.street">
            <label for="company_street">Street</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_street_number" name="company_street_number" class="form-control" v-model="user.street_number">
            <label for="company_street_number">No.</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_zipcode" name="company_zipcode" class="form-control" v-model="user.zipcode">
            <label for="company_zipcode">Zip code</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_place" name="company_place" class="form-control" v-model="user.place">
            <label for="company_place">City</label>
        </div>

        <div class="form-floating mb-3">
            <select id="company_country_id" name="company_country_id" class="form-control" v-model="user.country_id">
                <option value="">Please select</option>
                <option value="34">Afghanistan</option>
                <option value="35">Åland</option>
                <option value="36">Albania</option>
                <option value="37">Algeria</option>
                <option value="38">American Samoa</option>
                <option value="39">Andorra</option>
                <option value="40">Angola</option>
                <option value="41">Anguilla</option>
                <option value="42">Antarctica</option>
                <option value="43">Antigua and Barbuda</option>
                <option value="44">Argentina</option>
                <option value="45">Armenia</option>
                <option value="46">Aruba</option>
                <option value="47">Australia</option>
                <option value="23">Austria</option>
                <option value="48">Azerbaijan</option>
                <option value="49">Bahamas</option>
                <option value="50">Bahrain</option>
                <option value="51">Bangladesh</option>
                <option value="52">Barbados</option>
                <option value="53">Belarus</option>
                <option value="1">Belgium</option>
                <option value="54">Belize</option>
                <option value="55">Benin</option>
                <option value="56">Bermuda</option>
                <option value="57">Bhutan</option>
                <option value="58">Bolivia</option>
                <option value="59">Bonaire, Sint Eustatius, and Saba</option>
                <option value="60">Bosnia and Herzegovina</option>
                <option value="61">Botswana</option>
                <option value="62">Bouvet Island</option>
                <option value="63">Brazil</option>
                <option value="64">British Indian Ocean Territory</option>
                <option value="65">British Virgin Islands</option>
                <option value="66">Brunei</option>
                <option value="5">Bulgaria</option>
                <option value="67">Burkina Faso</option>
                <option value="68">Burundi</option>
                <option value="69">Cambodia</option>
                <option value="70">Cameroon</option>
                <option value="71">Canada</option>
                <option value="72">Cape Verde</option>
                <option value="73">Cayman Islands</option>
                <option value="74">Central African Republic</option>
                <option value="75">Chad</option>
                <option value="76">Chile</option>
                <option value="77">Christmas Island</option>
                <option value="78">Cocos (Keeling) Islands</option>
                <option value="79">Colombia</option>
                <option value="80">Comoros</option>
                <option value="81">Cook Islands</option>
                <option value="82">Costa Rica</option>
                <option value="14">Croatia</option>
                <option value="83">Cuba</option>
                <option value="84">Curaçao</option>
                <option value="22">Cyprus</option>
                <option value="9">Czech Republic</option>
                <option value="85">Democratic Republic of the Congo</option>
                <option value="13">Denmark</option>
                <option value="86">Djibouti</option>
                <option value="87">Dominica</option>
                <option value="88">Dominican Republic</option>
                <option value="89">East Timor</option>
                <option value="90">Ecuador</option>
                <option value="91">Egypt</option>
                <option value="92">El Salvador</option>
                <option value="93">Equatorial Guinea</option>
                <option value="94">Eritrea</option>
                <option value="21">Estonia</option>
                <option value="222">Eswatini</option>
                <option value="95">Ethiopia</option>
                <option value="96">Falkland Islands</option>
                <option value="97">Faroe Islands</option>
                <option value="98">Fiji</option>
                <option value="20">Finland</option>
                <option value="10">France</option>
                <option value="99">French Guiana</option>
                <option value="100">French Polynesia</option>
                <option value="101">French Southern and Antarctic Lands</option>
                <option value="102">Gabon</option>
                <option value="103">Gambia</option>
                <option value="104">Georgia</option>
                <option value="17">Germany</option>
                <option value="105">Ghana</option>
                <option value="106">Gibraltar</option>
                <option value="107">Greece</option>
                <option value="2">Greece</option>
                <option value="108">Greenland</option>
                <option value="109">Grenada</option>
                <option value="110">Guadeloupe</option>
                <option value="111">Guam</option>
                <option value="112">Guatemala</option>
                <option value="113">Guernsey</option>
                <option value="114">Guinea</option>
                <option value="115">Guinea-Bissau</option>
                <option value="116">Guyana</option>
                <option value="117">Haiti</option>
                <option value="118">Heard Island and McDonald Islands</option>
                <option value="119">Honduras</option>
                <option value="120">Hong Kong</option>
                <option value="11">Hungary</option>
                <option value="28">Iceland</option>
                <option value="121">India</option>
                <option value="122">Indonesia</option>
                <option value="123">Iran</option>
                <option value="124">Iraq</option>
                <option value="25">Ireland</option>
                <option value="125">Isle of Man</option>
                <option value="126">Israel</option>
                <option value="18">Italy</option>
                <option value="127">Ivory Coast</option>
                <option value="128">Jamaica</option>
                <option value="129">Japan</option>
                <option value="130">Jersey</option>
                <option value="131">Jordan</option>
                <option value="132">Kazakhstan</option>
                <option value="133">Kenya</option>
                <option value="134">Kiribati</option>
                <option value="135">Kosovo</option>
                <option value="136">Kuwait</option>
                <option value="137">Kyrgyzstan</option>
                <option value="138">Laos</option>
                <option value="26">Latvia</option>
                <option value="139">Lebanon</option>
                <option value="140">Lesotho</option>
                <option value="141">Liberia</option>
                <option value="142">Libya</option>
                <option value="30">Liechtenstein</option>
                <option value="3">Lithuania</option>
                <option value="7">Luxembourg</option>
                <option value="143">Macau</option>
                <option value="145">Madagascar</option>
                <option value="146">Malawi</option>
                <option value="147">Malaysia</option>
                <option value="148">Maldives</option>
                <option value="149">Mali</option>
                <option value="15">Malta</option>
                <option value="150">Marshall Islands</option>
                <option value="151">Martinique</option>
                <option value="152">Mauritania</option>
                <option value="153">Mauritius</option>
                <option value="154">Mayotte</option>
                <option value="155">Mexico</option>
                <option value="156">Micronesia</option>
                <option value="157">Moldova</option>
                <option value="158">Monaco</option>
                <option value="159">Mongolia</option>
                <option value="160">Montenegro</option>
                <option value="161">Montserrat</option>
                <option value="162">Morocco</option>
                <option value="163">Mozambique</option>
                <option value="164">Myanmar</option>
                <option value="165">Namibia</option>
                <option value="166">Nauru</option>
                <option value="167">Nepal</option>
                <option value="19">Netherlands</option>
                <option value="168">Netherlands Antilles</option>
                <option value="169">New Caledonia</option>
                <option value="170">New Zealand</option>
                <option value="171">Nicaragua</option>
                <option value="172">Niger</option>
                <option value="173">Nigeria</option>
                <option value="174">Niue</option>
                <option value="175">Norfolk Island</option>
                <option value="176">North Korea</option>
                <option value="144">North Macedonia</option>
                <option value="177">Northern Mariana Islands</option>
                <option value="29">Norway</option>
                <option value="178">Oman</option>
                <option value="179">Pakistan</option>
                <option value="180">Palau</option>
                <option value="181">Palestinian Territories</option>
                <option value="182">Panama</option>
                <option value="183">Papua New Guinea</option>
                <option value="184">Paraguay</option>
                <option value="185">Peru</option>
                <option value="186">Philippines</option>
                <option value="187">Pitcairn Islands</option>
                <option value="27">Poland</option>
                <option value="4">Portugal</option>
                <option value="188">Puerto Rico</option>
                <option value="189">Qatar</option>
                <option value="190">Republic of the Congo</option>
                <option value="8">Romania</option>
                <option value="192">Russia</option>
                <option value="193">Rwanda</option>
                <option value="191">Réunion</option>
                <option value="194">Saint Barthélemy</option>
                <option value="195">Saint Helena</option>
                <option value="196">Saint Kitts and Nevis</option>
                <option value="197">Saint Lucia</option>
                <option value="198">Saint Martin</option>
                <option value="199">Saint Pierre and Miquelon</option>
                <option value="200">Saint Vincent and the Grenadines</option>
                <option value="201">Samoa</option>
                <option value="202">San Marino</option>
                <option value="204">Saudi Arabia</option>
                <option value="205">Senegal</option>
                <option value="206">Serbia</option>
                <option value="207">Serbia and Montenegro</option>
                <option value="208">Seychelles</option>
                <option value="209">Sierra Leone</option>
                <option value="210">Singapore</option>
                <option value="211">Sint Maarten</option>
                <option value="16">Slovakia</option>
                <option value="12">Slovenia</option>
                <option value="212">Solomon Islands</option>
                <option value="213">Somalia</option>
                <option value="214">South Africa</option>
                <option value="215">South Georgia and the South Sandwich Islands</option>
                <option value="216">South Korea</option>
                <option value="217">South Sudan</option>
                <option value="6">Spain</option>
                <option value="218">Sri Lanka</option>
                <option value="219">Sudan</option>
                <option value="220">Suriname</option>
                <option value="221">Svalbard and Jan Mayen</option>
                <option value="24">Sweden</option>
                <option value="31">Switzerland</option>
                <option value="223">Syria</option>
                <option value="203">São Tomé and Príncipe</option>
                <option value="224">Taiwan</option>
                <option value="225">Tajikistan</option>
                <option value="226">Tanzania</option>
                <option value="227">Thailand</option>
                <option value="228">Togo</option>
                <option value="229">Tokelau</option>
                <option value="230">Tonga</option>
                <option value="231">Trinidad and Tobago</option>
                <option value="232">Tunisia</option>
                <option value="33">Turkey</option>
                <option value="233">Turkmenistan</option>
                <option value="234">Turks and Caicos Islands</option>
                <option value="235">Tuvalu</option>
                <option value="236">U.S. Virgin Islands</option>
                <option value="237">Uganda</option>
                <option value="238">Ukraine</option>
                <option value="239">United Arab Emirates</option>
                <option value="240">United Kingdom</option>
                <option value="32">United Kingdom</option>
                <option value="241">United States of America</option>
                <option value="242">Uruguay</option>
                <option value="243">Uzbekistan</option>
                <option value="244">Vanuatu</option>
                <option value="245">Vatican City</option>
                <option value="246">Venezuela</option>
                <option value="247">Vietnam</option>
                <option value="248">Wallis and Futuna</option>
                <option value="249">Western Sahara</option>
                <option value="250">Yemen</option>
                <option value="251">Zambia</option>
                <option value="252">Zimbabwe</option>
            </select>
            <label for="company_country_id">Country</label>
        </div>


        <div class="form-floating mb-3">
            <input type="text" id="company_phone" name="company_phone" class="form-control" v-model="user.phone">
            <label for="company_phone">Phone number</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" id="company_email" name="company_email" class="form-control" v-model="user.email">
            <label for="company_email">Email</label>
        </div>

        <h2>Payment data</h2>

        <div class="form-floating mb-3">
            <select id="bank_type" name="bank_type" class="form-control" v-model="user.bank_type">
                <option value="iban">Use IBAN</option>
                <option value="swift">Use account no. & SWIFT/BIC</option>
                <option value="paypal">Use PayPal</option>
                <option value="trustyfy">Use Trustyfy</option>
            </select>
            <label for="bank_type">Payment</label>
        </div>

        <div v-if="user.bank_type === 'iban'" class="form-floating mb-3">
            <input type="text" id="iban" name="iban" class="form-control" v-model="user.iban">
            <label for="iban">IBAN</label>
        </div>

        <div v-if="user.bank_type === 'swift'" class="form-floating mb-3">
            <input type="text" id="bank_account_number" name="bank_account_number" class="form-control" v-model="user.bank_account_number">
            <label for="bank_account_number">Account no.</label>
        </div>

        <div v-if="user.bank_type === 'swift'" class="form-floating mb-3">
            <input type="text" id="bank_swift" name="bank_swift" class="form-control" v-model="user.bank_swift">
            <label for="bank_swift">SWIFT/BIC</label>
        </div>

        <div v-if="user.bank_type === 'trustyfy'" class="input-group mb-3">
            <div class="form-floating">
                <input type="text" class="form-control" id="trustyfy_ens" name="trustyfy_ens" v-model="user.trustyfy_ens">
                <label for="trustyfy_ens">Trustyfy ENS</label>
            </div>
            <span class="input-group-text">.trustyfy</span>
        </div>

        <div v-if="user.bank_type === 'paypal'" class="form-floating mb-3">
            <input type="text" id="paypal_email" name="paypal_email" class="form-control" v-model="user.paypal_email">
            <label for="paypal_email">PayPal Email</label>
        </div>

        <h2>Other</h2>

        <div class="input-group mb-3">
            <div class="form-floating">
                <input type="text" class="form-control" id="target" name="target" v-model="user.target">
                <label for="target">Your personal monthly goal
                </label>
            </div>
            <span class="input-group-text">€</span>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import httpClient from '@/httpClient';

export default {
    name: 'ProfilePage',
    data() {
        return {
            fieldErrors: {},
            showNotificationButton: true
        }
    },
    computed: {
        ...mapGetters(['user']),
        avatarUrl() {
            return this.getImageOrPlaceholderUrl(this.user.avatar);
        }
    },
    mounted() {
        this.user.privacy_mode = !!this.user.privacy_mode;
        // Check the current notification permission and hide the button if already granted
        this.checkNotificationPermission();
    },
    methods: {
        getImageOrPlaceholderUrl(path) {
            return path ? process.env.VUE_APP_CWX_BASE_URL + path : require('@/assets/images/profile/default-avatar.png');
        },
        checkXname() {
            this.fieldErrors = {};
            httpClient.get('/pwa/partners/xname-check?xname=' + this.user.xname)
                .then(() => {})
                .catch((error) => {
                    console.log(error)
                    if (error.response.data.data.message === 'duplicate_field') {
                        this.fieldErrors.xname = error.response.data.data.info;
                    }
                });
        },
        async save() {
            if (Object.keys(this.fieldErrors).length > 0) {
                return;
            }

            this.fieldErrors = {};
            try {
                const response = await httpClient.post('/pwa/partners/me/save', this.user)
                if (response.status !== 200) {
                    throw new Error('Save failed with status ' + response.status);
                }
                return this.$emit('save-success');
            } catch {
                return this.$emit('save-error');
            }
        },
        checkNotificationPermission() {
            // Check permission and set the button visibility
            if (Notification.permission === "granted") {
                this.showNotificationButton = false;
            }
        },
        requestNotificationPermission() {
            // Request notification permission
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    this.showNotificationButton = false; // Hide the button if permission granted
                } else if (permission === "denied") {
                    alert("Notifications are currently disabled. You can enable them in your browser settings.");
                }
            });
        }
    }
}
</script>