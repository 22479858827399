import httpClient from '@/httpClient'; // Import the httpClient for server communication

// Function to request notification permission and handle subscription
export async function requestNotificationPermission(partnerId) {

    if (!partnerId) {
        console.error("No partner ID for notification subscription");
        return;
    }

    // Check if the browser supports notifications
    if (!('Notification' in window) || !('serviceWorker' in navigator)) {
        console.warn('This browser does not support notifications.');
        return;
    }

    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            console.warn('Notification permission denied.');
            return;
        }

        // Get the service worker registration
        const registration = await navigator.serviceWorker.ready;

        // Subscribe the user to push notifications
        await subscribeUserToPushNotifications(registration, partnerId);
    } catch (error) {
        console.error('Error during notification permission request:', error);
    }
}

// Function to subscribe the user to push notifications
async function subscribeUserToPushNotifications(registration, partnerId) {
    const applicationServerKey = urlB64ToUint8Array(process.env.VUE_APP_VAPID_KEY);

    try {
        // Create a subscription object
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
        });

        console.log('User is subscribed:', subscription);

        // Send the subscription details to the backend
        await sendSubscriptionToServer(subscription, partnerId);
    } catch (error) {
        console.error('Failed to subscribe the user:', error);
    }
}

// Function to send the subscription details to the backend
async function sendSubscriptionToServer(subscription, partnerId) {
    if (!partnerId) {
        console.error('User ID not found. Please log in first.');
        return;
    }

    try {
        const response = await httpClient.post('/pwa/webnotification-register', {
            subscription,
            partnerId: partnerId,
            platform: window.navigator.platform,
            userAgent: window.navigator.userAgent
        });

        if (response.data.data.success) {
            console.log('Subscription saved on server successfully.');
        } else {
            console.error('Failed to save subscription on server:', response.data.error);
        }
    } catch (error) {
        console.error('Failed to send subscription to server:', error);
    }
}

// Utility function to convert the VAPID key to a Uint8Array
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}