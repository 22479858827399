<template>
    <div class="dashboard-hero text-white">
        <img :src="heroImage" alt="crossworx" class="dashboard-hero-image" />
        <div class="dashboard-hero-content d-flex justify-content-between">
            <div class="user-avatar ps-3 pt-1">
                <img :src="avatarUrl" class="user-avatar-image">
            </div>
            <div class="user-info-right d-flex flex-column justify-content-end px-3 py-2">
                <div class="user-qr mb-2">
                    <qr-code
                            class="qr-code"
                            ref="heroQr"
                            :contents="user.tp_number"
                            module-color="#111"
                            position-ring-color="#c93a36"
                            position-center-color="#c93a36"
                            style="opacity: 0"
                            @click="showCwxIdQrModal = !showCwxIdQrModal"
                    >
                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                        <img src="@/assets/images/brand/x.svg" alt="qr" slot="icon" class="qr-logo" />
                    </qr-code>
                </div>
                <div class="user-cwx-id">
                    {{ user.tp_number }}
                </div>
                <div class="user-joined">
                    <small>Member since: {{ formatDate(user.insertDate) }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="container position-relative bg-light-gray mb-4">
        <div class="xpowered-icon position-absolute" v-if="user.customizedPWA" style="right:10px; top:10px; width: 35px;">
            <img src="@/assets/images/brand/xpowered.png" alt="xPowered" class="img-fluid" />
        </div>

        <div class="welcome-text">
            Hello, <span class="text-primary">{{ user.first_name || user.company || "User" }}</span>!
        </div>

        <div v-if="stats" class="dashboard-stats pt-3">
            <transition name="element-fade" @after-enter="showNext('stats2')">
                <div v-if="animated.stats1.visible" class="stats-row stats-row-bonus">
                    <h3 class="mb-2 text-nowrap">Your Achieved Bonus</h3>

                    <div class="row row-cols-2 row-cols-md-4 g-3 mb-4">
                        <div class="col">
                            <div class="neumorphic-card stats-current">
                                <span class="stats-bonus-amount amount-current text-nowrap">{{ stats.bonus }} €*</span>
                                <h5>Current Month</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="neumorphic-card">
                                <span class="stats-bonus-amount amount-year text-nowrap">{{ stats.bonusYear }} €*</span>
                                <h5>This Year</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="neumorphic-card">
                                <span class="stats-bonus-amount amount-paid text-nowrap">{{ stats.bonusPaid }} €</span>
                                <h5>Paid Out</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="neumorphic-card">
                                <span class="stats-bonus-amount amount-available text-nowrap">{{ stats.bonusOpen }} €</span>
                                <h5>Available</h5>
                            </div>
                        </div>
                  </div>
                </div>
            </transition>

            <transition name="element-fade" @after-enter="showNext('stats3')">
                <div v-if="animated.stats2.visible" class="stats-row">
                    <div class="row g-3 community-stats">
                        <h3 class="mb-0">Your Community</h3>
                        <div class="col-6">
                            <div class="neumorphic-card d-flex flex-row justify-content-between align-items-center">
                                <div class="d-flex flex-column justify-content-center">
                                    <span class="h1 mb-0 fw-bolder">{{ stats.community }}</span>
                                    <div>Members</div>
                                </div>
<!--                                <p><span class="h1 fw-bolder">{{ stats.community }}1200</span> Members</p>-->
                                <div class="icon icon-lg icon-member">
                                    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50 94.7921C47.2083 94.7921 44.375 94.0837 41.875 92.6253L17.125 78.3337C12.125 75.417 9 70.042 9 64.2503V35.7505C9 29.9588 12.125 24.5838 17.125 21.6671L41.875 7.37549C46.875 4.45882 53.0833 4.45882 58.125 7.37549L82.875 21.6671C87.875 24.5838 91 29.9588 91 35.7505V64.2503C91 70.042 87.875 75.417 82.875 78.3337L58.125 92.6253C55.625 94.0837 52.7917 94.7921 50 94.7921ZM50 11.4587C48.2917 11.4587 46.5417 11.9171 45 12.7921L20.25 27.0837C17.1667 28.8754 15.25 32.1671 15.25 35.7505V64.2503C15.25 67.792 17.1667 71.1254 20.25 72.9171L45 87.2087C48.0833 89.0004 51.9167 89.0004 55 87.2087L79.75 72.9171C82.8333 71.1254 84.75 67.8337 84.75 64.2503V35.7505C84.75 32.2088 82.8333 28.8754 79.75 27.0837L55 12.7921C53.4583 11.9171 51.7083 11.4587 50 11.4587Z" fill="currentColor"/>
                                        <path d="M49.9974 48.9578C42.9141 48.9578 37.1641 43.2077 37.1641 36.1244C37.1641 29.0411 42.9141 23.2913 49.9974 23.2913C57.0807 23.2913 62.8307 29.0411 62.8307 36.1244C62.8307 43.2077 57.0807 48.9578 49.9974 48.9578ZM49.9974 29.5413C46.3724 29.5413 43.4141 32.4994 43.4141 36.1244C43.4141 39.7494 46.3724 42.7078 49.9974 42.7078C53.6224 42.7078 56.5807 39.7494 56.5807 36.1244C56.5807 32.4994 53.6224 29.5413 49.9974 29.5413Z" fill="currentColor"/>
                                        <path d="M66.6693 72.5428C64.9609 72.5428 63.5443 71.1262 63.5443 69.4178C63.5443 63.6678 57.4609 58.9597 50.0026 58.9597C42.5443 58.9597 36.4609 63.6678 36.4609 69.4178C36.4609 71.1262 35.0443 72.5428 33.3359 72.5428C31.6276 72.5428 30.2109 71.1262 30.2109 69.4178C30.2109 60.2095 39.0859 52.7097 50.0026 52.7097C60.9193 52.7097 69.7943 60.2095 69.7943 69.4178C69.7943 71.1262 68.3776 72.5428 66.6693 72.5428Z" fill="currentColor"/>
                                    </svg>
                                </div>

                            </div>
                        </div>
                        <div class="col-6">
                            <div class="neumorphic-card d-flex flex-row justify-content-between align-items-center">
                                <div class="d-flex flex-column justify-content-center">
                                    <span class="h1 mb-0 fw-bolder">{{ stats.coinx }}</span>
                                    <div>Points</div>
                                </div>
<!--                                <p><span class="h1 fw-bolder">25000{{ stats.coinx }}</span>Members</p>-->
                                <h3><img class="coinx-logo" src="@/assets/images/brand/coinx.svg"></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="element-fade" @after-enter="showNext('ads')">
                <div v-if="animated.stats3.visible" class="stats-row daily-invites mt-4">
                    <div class="row" v-if="stats.dailyInviteBonusInfo && stats.dailyInviteBonusInfo.maxInvite">
                        <div class="mb-1">Daily target additional bonus for new community members</div>
                        <p class="d-flex align-items-center justify-content-between">
                            <span class="h1 text-primary fw-bolder">
                                {{ stats.dailyInviteBonusInfo.invited }} / {{ stats.dailyInviteBonusInfo.maxInvite }}
                            </span>
                            <span class="h5 bg-secondary text-white px-3 py-1">
                                {{ stats.dailyInviteBonusInfo.amount }} € of possible {{ stats.dailyInviteBonusInfo.maxAmount }} €
                            </span>
                        </p>
                    </div>
                </div>
            </transition>
        </div>
    </div>

    <div class="container position-relative">

        <transition name="element-fade">
            <div v-if="animated.ads.visible" class="dashboard-ads mb-5">
                <Splide class="ad-slider" :options="{ rewind: true, padding: '20vw', gap: '1.5rem', pagination: false, autoplay: true, lazyLoad: 'nearby' }" aria-label="My Favorite Images">
                    <SplideSlide v-for="(ad, i) in ads" v-bind:key="i">
                        <a class="ad-slide" :href="ad.linkUrl" target="_blank">
                            <img class="img-fluid" :src="ad.imageUrl" height="350" alt="">
                        </a>
                    </SplideSlide>

                </Splide>
            </div>
        </transition>

        <div class="dashboard-accordion mb-5">

            <accordion id="accordion-1" classes="accordion-flush">

                <accordion-item id="accordion-produktpartner-news" @accordion-opened="fetchProdctPartnerNews">
                    <template #header-button>
                        Product Partner News
                    </template>
                    <template #body>
                        <template v-if="partnerNews.length">
                            <div v-for="item in partnerNews" v-bind:key="item" class="partner-news-item">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <img v-if="item.logo" :src="getImageOrPlaceholderUrl(item.logo)" :alt="item.company" class="partner-news-item-logo img-fluid">
                                    <strong v-else>{{ item.company }}</strong>
                                    <br>
                                    <small>{{ formatDate(item.created_at) }}</small>
                                </div>
                                <h5>{{ item.title }}</h5>
                                <div v-html="item.content"></div>
                                <div class="d-grid">
                                    <a class="btn btn-primary" :href="item.cta_target" target="_blank">{{ item.cta_text }}</a>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            There are currently no active product partner news
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-inspirationen" @accordion-opened="fetchBlogPosts">
                    <template #header-button>
                        Inspirations
                    </template>
                    <template #body>
                        <template v-if="inspiration">

                            <Splide class="ad-slider" :options="{ rewind: true, padding: '5vw', gap: '1.5rem', autoWidth: true, perMove: 1, pagination: false, lazyLoad: 'nearby' }" aria-label="Inspiration">
                                <SplideSlide v-for="(post, i) in inspiration" v-bind:key="i" >
                                    <div class="card" style="width: 18rem; height: 30rem">
                                        <img :src="post.enclosure.url" class="card-img-top" alt="...">
                                        <div class="card-body d-flex flex-column">
                                            <h5 class="card-title">{{decodeHTML(post.title)}}</h5>
                                            <p class="card-text">{{decodeHTML(post.description)}}</p>
                                            <div class="flex-grow-1 d-flex align-items-end">
                                                <div class="flex-grow-1 d-grid">
                                                    <a @click="showModal(post)" class="btn btn-primary text-white">
                                                        Read more
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </Splide>


                        </template>
                        <template v-else>
                            [Inspirations will be displayed here]
                        </template>

                    </template>
                </accordion-item>

                <accordion-item id="accordion-meine-empfehlungsliste" @accordion-opened="fetchTransactions">
                    <template #header-button>
                        My Recommendation List
                    </template>
                    <template #body>
                        <template v-if="transactions">
                            <p>Your recommendations from the last 30 days are displayed here.</p>
                            <div v-for="transaction in transactions" v-bind:key="transaction" class="transaction-item d-flex gap-3">
                                <div class="transaction-logo">
                                    <img :src="getImageOrPlaceholderUrl(transaction.pp_logo)" :alt="transaction.pp_company" class="transaction-logo-image">
                                </div>
                                <div class="transaction-details flex-grow-1">
                                    <strong>{{ transaction.pp_company }}</strong><br>
                                    <span>{{ transaction.pp_city }}</span><br>
                                    <div class="d-flex">
                                        <small class="flex-grow-1">{{ transaction.date }} Uhr</small>
                                        <div class="text-end">
                                            <strong v-if="transaction.bonus == 0">pending</strong>
                                            <strong v-else>{{ currency(transaction.bonus) }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            You have no recommendations in the last 30 days.
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-meine-firstline" @accordion-opened="fetchFirstline">
                    <template #header-button>
                        My Firstline
                    </template>
                    <template #body>
                        <template v-if="firstline">
                            <p>Here you will find your firstline in chronological order. These are all the people you have personally invited into your network.</p>
                            <div v-for="year in firstline" v-bind:key="year" class="mb-4">
                                <div class="mb-3">
                                    <strong class="badge bg-primary">{{ year.year }}</strong>
                                </div>
                                <Splide class="firstline-slider" :options="{ perPage: 2, rewind: true, autoHeight: true, gap: '10px', pagination: false }" aria-label="Firstline">
                                    <SplideSlide v-for="(child, i) in year.children" v-bind:key="i">
                                        <div class="card">
                                            <div class="card-body d-flex flex-column align-items-center">
                                                <img :src="getImageOrPlaceholderUrl(child.avatar)" :alt="child.company" class="partner-avatar-image mb-2">
                                                <span class="badge-new badge bg-primary" v-if="child.new">NEW</span>
                                                <strong class="text-nowrap overflow-hidden" style="text-overflow: ellipsis;max-width: 100%;">{{ child.company }}</strong>
                                                <small>Member since {{ child.insertDate }}</small>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </template>
                        <template v-else>
                            You haven't recruited any members yet.
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-coinx-transaktionen" @accordion-opened="fetchCoinxLog">
                    <template #header-button>
                        coinX Transactions
                    </template>
                    <template #body>
                        <template v-if="coinxlog && coinxlog.length">
                            <p>Your coinX transactions from the last 30 days are displayed here.</p>
                            <div v-for="entry in coinxlog" v-bind:key="entry" class="transaction-item d-flex gap-3">
                                <div class="transaction-logo">
                                    <img src="@/assets/images/brand/coinx.svg" :alt="entry.sender" class="transaction-logo-image">
                                </div>
                                <div class="transaction-details flex-grow-1">
                                    <strong>{{ entry.sender }}</strong><br>
                                    <em>{{ entry.comment }}</em><br>
                                    <div class="d-flex">
                                        <small class="flex-grow-1">{{ entry.date }} Uhr</small>
                                        <div class="text-end">
                                            <strong>{{ entry.value }} cX</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            There are no coinX transactions in the last 30 days.
                        </template>
                    </template>
                </accordion-item>

                <accordion-item id="accordion-meine-nfts" :disabled="true">
                    <template #header-button>
                        My NFTs
                    </template>
                    <template #body>
                        There are no NFTs yet
                    </template>
                </accordion-item>

            </accordion>

        </div>

        <div class="dashboard-storelocator mb-5">
            <storelocator></storelocator>
        </div>

        <div class="dashboard-accordion mb-5">
            <h3>My Profile</h3>

            <router-link to="/profile" custom v-slot="{ navigate, href }">
                <button @click="navigate" :href="href" class="btn btn-accordion">
                    View or adjust
                </button>
            </router-link>

        </div>

        <div class="dashboard-accordion mb-3" v-if="cmsContent.default">
            <h3>Services</h3>
            <accordion id="accordion-hilfezentrum" classes="accordion-flush">
                <accordion-item :id="'accordion-' + item.identifier" v-for="item in cmsContent.default" v-bind:key="item">
                    <template #header-button>
                        {{ item.title }}
                    </template>
                    <template #body>
                        <div v-html="item.content"></div>
                        <div v-if="item.identifier === 'loyalty-cards'">
                            <loyalty-cards-component v-if="loyaltyCards && loyaltyCards.length" :cards="loyaltyCards"></loyalty-cards-component>
                            <div v-else>You didn't add any loyalty cards, yet.</div>
                        </div>
                    </template>
                </accordion-item>
            </accordion>
        </div>

        <div class="dashboard-accordion mb-4" v-if="cmsContent.legal">
            <h3>Legal Information</h3>
            <accordion id="accordion-rechtliches" classes="accordion-flush">

                <accordion-item :id="'accordion-' + item.identifier" v-for="item in cmsContent.legal" v-bind:key="item">
                    <template #header-button>
                        {{ item.title }}
                    </template>
                    <template #body>
                        <div v-html="item.content"></div>
                    </template>
                </accordion-item>
            </accordion>
        </div>

        <div class="d-grid mb-3">
            <button class="btn btn-primary btn-lg" @click="logout">Log out</button>
        </div>

    </div>

    <BlogPostModalComponent

            :show="modalVisible"
            :item="selectedItem"
            @close="closeModal"
    />

    <modal v-model:showModal="showCwxIdQrModal" title="Your crossworx ID" class="text-center">
        <template #body>
            <div class="mb-3">
                Please show this QR code to our product partners.
            </div>

            <qr-code
                    class="qr-code"
                    ref="modalQr"
                    :contents="user.tp_number"
                    module-color="#111"
                    position-ring-color="#c93a36"
                    position-center-color="#c93a36"
            >
                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                <img src="@/assets/images/brand/x.svg" alt="qr" slot="icon" class="qr-logo" />
            </qr-code>

            <div>
                By scanning this QR code, you generate your referral bonus.
            </div>
        </template>
    </modal>

</template>

<style scoped>
.neumorphic-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 1rem;
  transition: all 0.3s ease;
  box-shadow: 8px 8px 15px rgba(0,0,0,0.15);
}

.neumorphic-card:hover {
  transform: translateY(-5px);
  box-shadow: 12px 12px 20px rgba(0,0,0,0.1);
}

.neumorphic-card h5 {
  font-size: 0.9rem;
  margin-bottom: 0;
  font-weight: normal;
}

.neumorphic-card .stats-bonus-amount {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.community-stats .neumorphic-card {
   min-height: 80px;
}

.stats-current {
  box-shadow: 8px 8px 15px rgba(0,0,0,0.15);
}

.stats-current:hover {
  box-shadow: 12px 12px 20px rgba(0,0,0,0.1);
}

.stats-current h5, .stats-current .stats-bonus-amount {
  color: white;
}
</style>


<script>
import {mapActions, mapGetters} from 'vuex'
import Storelocator from '@/views/components/StorelocatorComponent.vue';
import BlogPostModalComponent from '@/views/components/BlogPostModalComponent.vue';
import Modal from '@/views/components/ModalComponent.vue';
import Accordion from "@/views/components/accordion/AccordionComponent.vue";
import AccordionItem from "@/views/components/accordion/AccordionItemComponent.vue";
import httpClient from '@/httpClient';
import { decodeHTMLEntities } from '@/utils/stringUtils';
import LoyaltyCardsComponent from "@/views/components/LoyaltyCardsComponent.vue";

export default {
    name: 'DashboardPage',
    components: {LoyaltyCardsComponent, AccordionItem, Accordion, Storelocator, Modal, BlogPostModalComponent },
    data() {
        return {
            showCwxIdQrModal: false,
            animated: {
                stats1: { visible: false },
                stats2: { visible: false },
                stats3: { visible: false },
                ads: { visible: false },
            },
            stats: {},
            ads: {},
            partnerNews: [],
            transactions: [],
            firstline: [],
            coinxlog: [],
            cmsContent: {},
            inspiration: [],
            modalVisible: false,
            selectedItem: null,
            loyaltyCards: []
        };
    },
    computed: {
        ...mapGetters(['user']),
        avatarUrl() {
            return this.getImageOrPlaceholderUrl(this.user.avatar);
        },
        heroImage() {
            if (this.user.customizedPWA && this.user.customizedPWA.heroImage) {
                return process.env.VUE_APP_CWX_BASE_URL + this.user.customizedPWA.heroImage;
            }
            return require('@/assets/images/dashboard/dashboard-hero-image.webp');
        }
    },
    methods: {
        ...mapActions(['logout']),
        formatDate(datetime) {
            const date = new Date(datetime);
            try {
                return new Intl.DateTimeFormat('de-DE', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }).format(date);
            } catch {
                return '';
            }
        },
        qrAnimation(refName, out = false) {
            this.$refs[refName].style.opacity = "";
            this.$refs[refName].animateQRCode((targets, _x, _y, _count, entity) => (
                {
                    targets,
                    from: entity === 'module' ? 200 * Math.random() : 200,
                    duration: !out ? 800 : 400,
                    web: {
                        opacity: !out ? [0, 1] : [1, 0],
                        scale: !out ? [0.5, 1.1, 1] : [1, 1.25]
                    }
                })
            )
        },
        showNext(ident) {
            if (this.animated[ident]) {
                this.animated[ident].visible = true;
            }
        },
        getImageOrPlaceholderUrl(path) {
            return path ? this.getImageUrl(path) : require('@/assets/images/profile/default-avatar.png');
        },
        getImageUrl(path) {
            return process.env.VUE_APP_CWX_BASE_URL + path;
        },
        currency(number, locale = 'de-DE', currency = 'EUR') {
            const formatter = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currency
            });

            return formatter.format(number);
        },
        async fetchStats() {
            try {
                const response = await httpClient.get('/pwa/dashboard-stats')
                this.stats = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchAds() {
            try {
                const response = await httpClient.get('/pwa/dashboard-ads')
                this.ads = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchCmsContent() {
            try {
                const response = await httpClient.get('/pwa/cms-content')
                this.cmsContent = response.data.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchProdctPartnerNews(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/partner-news')
                this.partnerNews = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchTransactions(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/transactions');
                this.transactions = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchFirstline(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/firstline')
                this.firstline = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchCoinxLog(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/coinxlog')
                this.coinxlog = response.data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                e.loading = false;
            }
        },
        async fetchBlogPosts(e) {
            e.loading = true;
            try {
                const response = await httpClient.get('/pwa/cwx-rss-feed');
                const xmlData = response.data.data;
                const parser = new DOMParser();
                const doc = parser.parseFromString(xmlData, "text/xml");

                // const items = doc.querySelectorAll("item");
                // const posts = [];
                const items = doc.getElementsByTagName("item");

                const parsedItems = Array.from(items).map(item => {
                    return {
                        title: item.querySelector("title")?.textContent.trim(),
                        description: item.querySelector("description")?.textContent.trim(),
                        link: item.querySelector("link")?.textContent.trim(),
                        guid: item.querySelector("guid")?.textContent.trim(),
                        category: item.querySelector("category")?.textContent.trim(),
                        pubDate: new Date(item.querySelector("pubDate")?.textContent.trim()),
                        creator: item.getElementsByTagNameNS("http://purl.org/dc/elements/1.1/", "creator")[0]?.textContent.trim(),
                        content: item.getElementsByTagNameNS("http://purl.org/rss/1.0/modules/content/", "encoded")[0]?.textContent.trim(),
                        enclosure: {
                            url: item.querySelector("enclosure")?.getAttribute("url"),
                            type: item.querySelector("enclosure")?.getAttribute("type")
                        }
                    };
                });
                this.inspiration = parsedItems.sort((a, b) => b.pubDate - a.pubDate);
                e.loading=false
            } catch (error) {
                console.error('Error fetching blog posts:', error);
                return []; // Return empty array on error
            }
        },
        async fetchLoyaltyCards() {
            try {
                const response = await httpClient.get('/pwa/partners/loyalty-cards');
                this.loyaltyCards = response.data.data
            } catch (error) {
                console.error('Error fetching loyalty cards:', error);
            }
        },
        showModal(item) {
            console.log('Showing modal for item:', item);
            this.selectedItem = item;
            this.modalVisible = true;
        },
        closeModal() {
            this.modalVisible = false;
            this.selectedItem = null;
        },
        decodeHTML(text) {
            return decodeHTMLEntities(text);
        }
    },
    mounted() {
        this.$refs['heroQr'].addEventListener('codeRendered', () => {
            this.qrAnimation('heroQr');
        })

        setTimeout(() => {
            this.animated.stats1.visible = true;
        }, 500)

        this.fetchStats();
        this.fetchAds();
        this.fetchCmsContent();
        this.fetchLoyaltyCards();
    }
}
</script>
