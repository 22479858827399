<template>

    <div class="lc-wrapper">
        <div v-if="cards" id="lc-card-container" class="lc-card-container" :style="{ height: (cards.length * 50 + 250) + 'px' }">
            <div class="lc-card" :class="`lc-card-type-${card.code_type}`" v-for="(card, i) in cards" v-bind:key="i" :style="{ 'background-color': card.color, 'top': `${i * 50}px` }">
                <img v-if="card.logo" :src="getImageUrl(card.logo)" class="lc-preview-image" :alt="card.name">
                <div class="lc-preview-name" v-else>{{ card.name }}</div>

                <img v-if="card.logo" :src="getImageUrl(card.logo)" class="lc-image" :alt="card.name">
                <div v-else class="lc-name"><span>{{ card.name }}</span></div>

                <div class="lc-code">
                    <canvas v-if="card.code_type === 'barcode'" :ref="`barcode-${i}`" class="lc-barcode"></canvas>
                    <qr-code v-else
                             class="lc-qr-code"
                            :contents="card.number"
                            module-color="#000"
                            position-ring-color="#000"
                            position-center-color="#000"
                            style="
                                width: 100px;
                                height: 100px;
                                margin: 5px auto;
                                background-color: #fff;
                            "
                    ></qr-code>
                    <div class="lc-number">{{ card.number }}</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import JsBarcode from "jsbarcode";

export default {
    name: 'LoyaltyCardsComponent',
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    mounted() {
        this.cards.forEach((card, i) => {
            if (this.$refs[`barcode-${i}`]) {
                let canvas = this.$refs[`barcode-${i}`];
                JsBarcode(canvas, card.number, {
                    format: "code128",
                    lineColor: "#000",
                    width: 2,
                    height: 40,
                    displayValue: false
                });
            }
        });
    },
    methods: {
        getImageUrl(path) {
            return process.env.VUE_APP_CWX_BASE_URL + path;
        },
    }
}
</script>

<style scoped>
.lc-wrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    overflow: visible;
}
.lc-card-container {
    position: relative;
    height: 450px;
    padding: 40px 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    perspective: 1000px;
}
.lc-card {
    position: absolute;
    width: calc(100% - 40px);
    max-width: 340px; /* Adjusted for typical credit card width */
    aspect-ratio: 1.586 / 1; /* Standard credit card aspect ratio */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.25), 0 6px 20px rgba(0,0,0,0.25);
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.25rem;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    line-height: 1;
}
.lc-card.lc-card-type-barcode {
    flex-direction: column;
}
.lc-card.lc-card-type-qr {
    gap: 10px;
}

.lc-card:hover {
    transform: translateY(-20px) scale(1.05);
    box-shadow: 0 12px 24px rgba(0,0,0,0.2), 0 18px 40px rgba(0,0,0,0.15);
    z-index: 5;
}
.lc-preview-image {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100px;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    object-position: top left;
    transition: opacity 0.25s ease-in-out;
}
.lc-preview-name {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 0.75rem;
    align-self: start;
    transition: opacity 0.25s ease-in-out;
    display: flex;
    align-items: center;
    height: 30px;
}
.lc-card:hover .lc-preview-image,
.lc-card:hover .lc-preview-name,
.lc-card:last-child .lc-preview-image,
.lc-card:last-child .lc-preview-name {
    opacity: 0;
}
.lc-card .lc-image {
    opacity: 0;
    width: 100%;
    height: 40%;
    -o-object-fit: contain;
    object-fit: contain;
    margin-bottom: 15px;
    transition: opacity 0.25s ease-in-out;
}
.lc-card.lc-card-type-qr .lc-image {
    max-height: 100%;
    max-width: 50%;
    height: auto;
    margin-bottom: 0;
}
.lc-name {
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
}
.lc-card:hover .lc-image,
.lc-card:hover .lc-name,
.lc-card:last-child .lc-image,
.lc-card:last-child .lc-name {
    opacity: 1;
}
.lc-code {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}
.lc-card:hover .lc-code,
.lc-card:last-child .lc-code {
    opacity: 1;
}
.lc-number {
    margin-top: 5px;
    font-size: 0.875rem;
}
</style>
