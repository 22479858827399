/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      notifyUserAboutUpdate(registration)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

function notifyUserAboutUpdate(registration) {
  const updateNotification = document.createElement('div')
  updateNotification.className = 'update-notification'
  updateNotification.innerHTML = `
    <p>Neue Version verfügbar. <a href="#" id="refresh">Jetzt neuladen</a></p>
  `
  document.body.appendChild(updateNotification)

  document.getElementById('refresh').addEventListener('click', () => {
    console.log("Refresh click", registration);
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      registration.waiting.addEventListener('statechange', (event) => {
        console.log("Check state", event);
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      })
    }

    updateNotification.style.display = 'none'
  })
}
