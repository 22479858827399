<template>
    <BaseLayout />
</template>

<script>
import BaseLayout from '@/views/layout/BaseLayout.vue'

export default {
    name: 'App',
    components: {
        BaseLayout
    }
}
</script>